import React from 'react';
import { useCursor } from 'context/CursorContext';
import './style.scss';

const Navigation = ({
  active,
  onClick
}) => {
  const { onMouseEnter, onMouseLeave, onMouseMove, changeCursorColor } = useCursor();
  return (
    <nav
      className="c_navigation"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      <div
        onClick={() => onClick(0)}
        className={`${active === 1 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">01</div>
          <div className="label">Home</div>
        </div>
      </div>
      <div
        onClick={() => onClick(1)}
        className={`${active === 2 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">02</div>
          <div className="label">3 Facts</div>
        </div>
      </div>
      <div
        onClick={() => onClick(2)}
        className={`${active === 3 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">03</div>
          <div className="label">How it works</div>
        </div>
      </div>
      <div
        onClick={() => onClick(3)}
        className={`${active === 4 || active === 5 || active === 6 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">04</div>
          <div className="label">Features</div>
        </div>
      </div>
      <div
        onClick={() => onClick(6)}
        className={`${active === 7 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">05</div>
          <div className="label">Why Bill Trade?</div>
        </div>
      </div>
      <div
        onClick={() => onClick(7)}
        className={`${active === 8 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">06</div>
          <div className="label">One account</div>
        </div>
      </div>
      <div
        onClick={() => onClick(8)}
        className={`${active === 9 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">07</div>
          <div className="label">About Bill Trade</div>
        </div>
      </div>
      <div
        onClick={() => onClick(9)}
        className={`${active === 10 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">08</div>
          <div className="label">Affiliate program</div>
        </div>
      </div>
      <div
        onClick={() => onClick(10)}
        className={`${active === 11 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">09</div>
          <div className="label">Bill Trade benefits</div>
        </div>
      </div>
      <div
        onClick={() => onClick(11)}
        className={`${active === 12 ? 'c_navigation__item c_navigation__item__active' : 'c_navigation__item'}`}
      >
        <div className="c_navigation__item-content">
          <div className="divider"></div>
          <div className="number">10</div>
          <div className="label">Contact us</div>
        </div>
      </div>


      {/* <button onClick={() => onClick(0)} className={`nav__item ${active === 1 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>01 HOME</span>
        </span>
      </button>
      <button onClick={() => onClick(1)} className={`nav__item ${active === 2 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>02</span>
        </span>
      </button>
      <button onClick={() => onClick(2)} className={`nav__item ${active === 3 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>03</span>
        </span>
      </button>
      <button onClick={() => onClick(3)} className={`nav__item ${active === 4 || active === 5 || active === 6 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>04</span>
        </span>
      </button>
      <button onClick={() => onClick(6)} className={`nav__item ${active === 7 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>05</span>
        </span>
      </button>
      <button onClick={() => onClick(7)} className={`nav__item ${active === 8 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>06</span>
        </span>
      </button>
      <button onClick={() => onClick(8)} className={`nav__item ${active === 9 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>07</span>
        </span>
      </button>
      <button onClick={() => onClick(9)} className={`nav__item ${active === 10 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>08</span>
        </span>
      </button>
      <button onClick={() => onClick(10)}  className={`nav__item ${active === 11 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>09</span>
        </span>
      </button>
      <button onClick={() => onClick(11)} className={`nav__item ${active === 12 ? 'nav__item--current' : ''}`}>
        <span className="nav__item-title">
          <span>10</span>
        </span>
      </button> */}
    </nav>
  );
}

export default Navigation;
