import React from 'react'

import VerticalDivider from '../../components/VerticalDivider/VerticalDivider';
import { useCursor } from 'context/CursorContext';
import { Determinator, withLang } from "react-multi-language";

const SectionFourSlide = ({
  title,
  top,
  bottom,
}) => {
  const { changeCursorColor, onMouseEnter, onMouseLeave, onMouseMove } = useCursor();

  return (
    <section
      className="p_main-page__advantages__main"
      onMouseEnter={() => changeCursorColor('black')}
      onMouseLeave={() => changeCursorColor('white')}
    >
      <div className="container">
        <div className="flex">
          <div className="item">

            <div className="p_main-page__advantages__main__stacked-item">
              <div
                className="p_main-page__advantages__main__stacked-item__title"
                // ref={ref => {this.advantagesRefs.title1 = ref}}
                dangerouslySetInnerHTML={{ __html: top.title }}
              />
              <div
                className="p_main-page__advantages__main__stacked-item__description"
                // ref={ref => {this.advantagesRefs.subtitle1 = ref}}
                dangerouslySetInnerHTML={{ __html: top.subtitle }}
              />
            </div>

            <div className="p_main-page__advantages__main__stacked-item">
              <div
                className="p_main-page__advantages__main__stacked-item__title"
                // ref={ref => {this.advantagesRefs.title2 = ref}}
                dangerouslySetInnerHTML={{ __html: bottom.title }}
              />
              <div
                className="p_main-page__advantages__main__stacked-item__description"
                // ref={ref => {this.advantagesRefs.subtitle2 = ref}}
                dangerouslySetInnerHTML={{ __html: bottom.subtitle }}
              />
            </div>

          </div>
          <div className="item right">
            <div
              className="p_main-page__advantages__main__title"
              // ref={ref => {this.advantagesRefs.mainTitle = ref}}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export const SectionFourOne = withLang({
  en: {
    title: `Total freedom for the traders`,
    top: {
      title: `Trade where ever you want -  Earn with Bill Trade`,
      subtitle: `You can use any programs,
      signals, terminals to signal on our platform`
    },
    bottom: {
      title: `No trading barriers `,
      subtitle: `Trade using any type of order, 
      leverage and asset`
    },
  },
  ru: {
    title: `Полная свобода для трейдеров`,
    top: {
      title: `Торгуйте, где хотите - зарабатывайте с Bill Trade`,
      subtitle: `Вы можете использовать любые программы, сигналы, терминалы чтобы транслировать на нашей платформе`
    },
    bottom: {
      title: `Никаких торговых ограничений`,
      subtitle: `Торгуйте любым типом ордера, плечем и активом`
    },
  },
})(({ langProps }) => <SectionFourSlide {...langProps} />);

export const SectionFourTwo = withLang({
  en: {
    title: `Analyst`,
    top: {
      title: `Quality copying`,
      subtitle: `Analyst - Creates his own copying strategy and shares it with the other users of the platform.`
    },
    bottom: {
      title: `The best results`,
      subtitle: `By analyzing the statistic of the trader, an analyst can enhance his copying results, as well as dynamically change the allocated percentage and the numbers of traders in his portfolio`
    },
  },
  ru: {
    title: `Аналитик`,
    top: {
      title: `Качественное копирование`,
      subtitle: `Аналитик - Создаёт свои стратегии копирования и транслирует их для дрыгих клиентов`
    },
    bottom: {
      title: `Лучшие результаты`,
      subtitle: `Анализируя статистику трейдера, аналитик может улучшить результат копирования, а так же динамически изменять процент и количество трейдеров в своем портфеле`
    },
  },
})(({ langProps }) => <SectionFourSlide {...langProps} />);

export const SectionFourThree = withLang({
  en: {
    title: `Multiplier`,
    top: {
      title: `Risk management`,
      subtitle: `The ability to adjust risks (Profit and loss) relative to the trader. You can make a conservative trader aggressive and vice versa.`
    },
    bottom: {
      title: `Good diversification`,
      subtitle: `The ability to level the average profitability of traders and combine them into one portfolio`
    },
  },
  ru: {
    title: `Множитель`,
    top: {
      title: `Эффективный риск менеджмент `,
      subtitle: `Возможность регулировать риски(Прибыли и убытки) относительно трейдера. Вы можете из консервативного трейдера сделать - агрессивного и наоборот.`
    },
    bottom: {
      title: `Хорошая диверсификация`,
      subtitle: `Возможность уровнять среднюю доходность трейдеров и обьеденить их в один портфель`
    },
  }
})(({ langProps }) => <SectionFourSlide {...langProps} />);
