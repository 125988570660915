import React, { PureComponent } from 'react';
import Button from '../../components/Button';
import VerticalDivider from '../../components/VerticalDivider/VerticalDivider';
import { Determinator, withLang } from "react-multi-language";
import { TweenMax } from 'gsap';
import { animated } from 'react-spring';

class SectionNineNoLang extends PureComponent {
  divider = null;
  title = null;
  svg = null;
  button = null;
  item1 = null;
  item2 = null;
  item3 = null;
  item4 = null;
  ref1 = null;
  ref2 = null;
  ref3 = null;
  affLevel1 = null;
  affLevel2 = null;
  affLevel3 = null;

  componentDidUpdate() {
    if (this.props.activeSection === 10) {
      this.onInit();
    }
  }

  onInit = () => {
    TweenMax.to(
      this.divider,
      .7,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );

    TweenMax.to(
      this.title,
      .7,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );

    TweenMax.to(
      this.button,
      .7,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );

    setTimeout(() => {
      TweenMax.to(
        this.item1,
        .7,
        {
          transform: 'transform(0px 0px)',
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
      TweenMax.to(
        this.affLevel1,
        .7,
        {
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
      TweenMax.to(
        this.text1,
        .7,
        {
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
      TweenMax.to(
        this.line1,
        .7,
        {
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
    }, 100);
    setTimeout(() => {
      TweenMax.to(
        this.item2,
        .7,
        {
          transform: 'transform(0px 0px)',
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
      TweenMax.to(
        this.affLevel2,
        .7,
        {
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
      TweenMax.to(
        this.text2,
        .7,
        {
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
      TweenMax.to(
        this.line2,
        .7,
        {
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
    }, 200);
    setTimeout(() => {
      TweenMax.to(
        this.affLevel3,
        .7,
        {
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
      TweenMax.to(
        this.item3,
        .7,
        {
          transform: 'transform(0px 0px)',
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
      TweenMax.to(
        this.text3,
        .7,
        {
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
      TweenMax.to(
        this.line3,
        .7,
        {
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
    }, 300);
    setTimeout(() => {
      TweenMax.to(
        this.item4,
        .7,
        {
          transform: 'transform(0px 0px)',
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
    }, 600);
  }

  renderSvg = () => {
    return (
      <svg ref={ref => {this.divider = ref}} className="divider" width="16" height="500" viewBox="0 0 16 500">
        <g transform="translate(-732 -5927.5)">
          <text transform="translate(739 6178) rotate(90)" fill="#9B9B9D" fontSize="12" fontFamily="Helvetica" letterSpacing="0.35em"><tspan x="-178.457" y="0">
            <Determinator>
              {{
                ru: `ПРИГЛАСИТЕ ДРУГА ПРИСОЕДИНИТЬСЯ К BILLTRADE`,
                en: `INVITE A FRIEND TO JOIN BILLTRADE`
              }}
            </Determinator>
          </tspan></text>
          <line y2="500" transform="translate(732.5 5927.5)" fill="none" stroke="#9B9B9D" strokeWidth="1"/>
        </g>
      </svg>
    );
  }
  render() {
    const {
      title1,
      title2,
      vertical,
      button,
      item1,
      item2,
      item3,
      item4
    } = this.props.langProps;
    return (
      <section className="p_main-page__affiliate-program">
        {this.renderSvg()}
        <div className="wrapper">
          <div className="container flex">
            <div className="item"></div>
            <div className="p_main-page__affiliate-program__title item" ref={ref => {this.title = ref}}>
              <div>{title1}</div>
              <div>{title2}</div>
            </div>
          </div>
          <div className="container flex">
            <div className="item">
              <div
                ref={ref => {this.item1 = ref}}
                className="p_main-page__affiliate-program__description-item"
              >
                {item1}
              </div>
              <div
                ref={ref => {this.item2 = ref}}
                className="p_main-page__affiliate-program__description-item"
              >
                {item2}
              </div>
              <div
                ref={ref => {this.item3 = ref}}
                className="p_main-page__affiliate-program__description-item"
              >
                {item3}
              </div>
              <div
                ref={ref => {this.item4 = ref}}
                className="p_main-page__affiliate-program__description-item"
              >
                {item4}
              </div>
            </div>
            <div className="item">
              <animated.svg
                className="stats"
                width="298.5"
                height="358.13"
                viewBox="0 0 298.5 358.13"
              >
                <defs>
                  <linearGradient id="linear-gradient-gray" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#ccc"/>
                    <stop offset="1" stopColor="#fff"/>
                  </linearGradient>
                  <filter id="Polygon_37" x="13.745" y="217.857" width="154.959" height="140.273" filterUnits="userSpaceOnUse">
                    <feOffset dy="10" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="7.5" result="blur"/>
                    <feFlood floodOpacity="0.251"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                  </filter>
                  {/* <filter id="Polygon_38" x="14" y="217.5" width="154.959" height="140.273" filterUnits="userSpaceOnUse">
                    <feOffset dy="10" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="7.5" result="blur-2"/>
                    <feFlood floodOpacity="0.502"/>
                    <feComposite operator="in" in2="blur-2"/>
                    <feComposite in="SourceGraphic"/>
                  </filter> */}
                  <filter id="Path_3908" x="6.873" y="116.145" width="168.704" height="145.767" filterUnits="userSpaceOnUse">
                    <feOffset dy="10" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="7.5" result="blur-3"/>
                    <feFlood floodOpacity="0.502"/>
                    <feComposite operator="in" in2="blur-3"/>
                    <feComposite in="SourceGraphic"/>
                  </filter>
                  <filter id="Path_3917" x="7" y="116.5" width="168.704" height="145.767" filterUnits="userSpaceOnUse">
                    <feOffset dy="10" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="7.5" result="blur-4"/>
                    <feFlood floodOpacity="0.502"/>
                    <feComposite operator="in" in2="blur-4"/>
                    <feComposite in="SourceGraphic"/>
                  </filter>
                  <filter id="Path_3907" x="0" y="0" width="182.449" height="159.559" filterUnits="userSpaceOnUse">
                    <feOffset dy="10" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="7.5" result="blur-5"/>
                    <feFlood floodOpacity="0.502"/>
                    <feComposite operator="in" in2="blur-5"/>
                    <feComposite in="SourceGraphic"/>
                  </filter>
                  <filter id="Path_3907-2" x="0" y="0.5" width="182.449" height="159.559" filterUnits="userSpaceOnUse">
                    <feOffset dy="10" input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="7.5" result="blur-6"/>
                    <feFlood floodOpacity="0.251"/>
                    <feComposite operator="in" in2="blur-6"/>
                    <feComposite in="SourceGraphic"/>
                  </filter>
                </defs>
                <g transform="translate(-1083.5 -8650.5)">

                  <g style={{ opacity: 0 }} ref={ref => {this.affLevel3 = ref}} transform="translate(1119.745 8880.857)">
                    <g transform="matrix(1, 0, 0, 1, -36.25, -230.36)" filter="url(#Polygon_37)">
                      <path d="M82.47,0l27.49,47.637L82.47,95.273H27.49L0,47.637,27.49,0Z" transform="translate(36.25 230.36)" opacity="0.5" fill="url(#linear-gradient-gray)"/>
                    </g>
                    <g transform="matrix(1, 0, 0, 1, -36.25, -230.36)">
                      <path d="M82.47,0l27.49,47.637L82.47,95.273H27.49L0,47.637,27.49,0Z" transform="translate(36.5 230)" opacity="0.5" fill="url(#linear-gradient-gray)"/>
                    </g>
                    <text transform="translate(36.255 71.642)" fill="#3a3a3a" fontSize="69" fontFamily="Gilroy-Heavy, Gilroy" fontWeight="800" letterSpacing="0.025em"><tspan x="0" y="0">3</tspan></text>
                  </g>

                  <g style={{ opacity: 0 }} ref={ref => {this.affLevel2 = ref}} transform="translate(1112.872 8779.145)">
                    <g transform="matrix(1, 0, 0, 1, -29.37, -128.64)">
                      <path d="M92.778,0,123.7,50.383,92.778,100.766H30.926L0,50.383,30.926,0Z" transform="translate(29.37 128.64)" opacity="0.75" fill="url(#linear-gradient-gray)"/>
                    </g>
                    <g transform="matrix(1, 0, 0, 1, -29.37, -128.64)" filter="url(#Path_3907-2)">
                      <path d="M92.778,0,123.7,50.383,92.778,100.766H30.926L0,50.383,30.926,0Z" transform="translate(29.5 129)" opacity="0.75" fill="url(#linear-gradient-gray)"/>
                    </g>
                    <text transform="translate(43.127 74.355)" fill="#3a3a3a" fontSize="69" fontFamily="Gilroy-Heavy, Gilroy" fontWeight="800" letterSpacing="0.025em"><tspan x="0" y="0">2</tspan></text>
                  </g>

                  <line style={{ opacity: 0 }} ref={ref => {this.line1 = ref}} x2="138.121" transform="translate(1243.5 8720.385)" fill="none" stroke="#707070" strokeWidth="1"/>
                  <path style={{ opacity: 0 }} ref={ref => {this.line2 = ref}} d="M-35.229.223,108.942,0" transform="translate(1272.679 8828.97)" fill="none" stroke="#707070" strokeWidth="1"/>
                  <path style={{ opacity: 0 }} ref={ref => {this.line3 = ref}} d="M-45.521,0H105.723" transform="translate(1275.898 8929.995)" fill="none" stroke="#707070" strokeWidth="1"/>

                  <text style={{ opacity: 0 }} ref={ref => {this.text1 = ref}} transform="translate(1382 8716.5)" fill="#3a3a3a" fontSize="22" fontFamily="Gilroy-Bold, Gilroy" fontWeight="700" letterSpacing="0.025em"><tspan x="-91.476" y="0">Lv 1 = 3%</tspan></text>
                  <text style={{ opacity: 0 }} ref={ref => {this.text2 = ref}} transform="translate(1382 8825.5)" fill="#3a3a3a" fontSize="22" fontFamily="Gilroy-Bold, Gilroy" fontWeight="700" letterSpacing="0.025em"><tspan x="-94.776" y="0">Lv 2 = 2%</tspan></text>
                  <text style={{ opacity: 0 }} ref={ref => {this.text3 = ref}} transform="translate(1382 8925.5)" fill="#3a3a3a" fontSize="22" fontFamily="Gilroy-Bold, Gilroy" fontWeight="700" letterSpacing="0.025em"><tspan x="-92.136" y="0">Lv 3 = 1%</tspan></text>

                  <g style={{ opacity: 0 }} ref={ref => {this.affLevel1 = ref}} transform="translate(1106 8663)">
                    <g transform="matrix(1, 0, 0, 1, -22.5, -12.5)" filter="url(#Path_3907-2)">
                      <path d="M103.087,0l34.362,57.279-34.362,57.279H34.362L0,57.279,34.362,0Z" transform="translate(22.5 13)" fill="url(#linear-gradient-gray)"/>
                    </g>
                    <text transform="translate(55 80.5)" fill="#3a3a3a" fontSize="68" fontFamily="Gilroy-Heavy, Gilroy" fontWeight="800" letterSpacing="0.025em"><tspan x="0" y="0">1</tspan></text>
                  </g>
                </g>
              </animated.svg>
              {/* <Button
                refc={ref => {this.button = ref}}
              >
                {button}
              </Button> */}
            </div>
        </div>
        </div>
      </section>
    )
  }
}

const SectionNine = withLang({
  en: {
    title1: `Affiliate`,
    title2: `program`,
    vertical: `Invite a friend to join Bill Trade`,
    button: `Invite friends`,
    item1: `Get 3% of the profit he makes on Bill Trade as an investor. `,
    item2: `If he invites a friend, you’ll get 2% of his friend’s profit. `,
    item3: `You’ll also benefit in case the last one invites his friend - 1% of the profit.`,
    item4: `If a trader joins Bill Trade via your affiliate link, you’ll get 1% of his profit.`,
  },
  ru: {
    title1: `Партнерская`,
    title2: `программа`,
    vertical: `Пригласите друга присоединиться к BillTrade`,
    button: `Пригласить друзей`,
    item1: `Получайте 3% от прибыли, которую он получает от платформы как инвестор.`,
    item2: `Если он пригласит друга, вы будете получать 2% от прибыли его друга`,
    item3: `Если последний пригласит своего друга, вы будете получать 1% от его прибыли`,
    item4: `Если трейдер присоединится к BillTrade по вашей партнерской ссылке, вы будете получать 1% от его прибыли`,
  }
})(SectionNineNoLang);

export default SectionNine;
