import React, { PureComponent } from 'react';
import { Context as CursorContext } from '../../context/CursorContext';
import VizSensor from 'react-visibility-sensor';
import { TweenMax } from 'gsap';
import Button from '../../components/Button/Button';
import { Determinator, withLang } from "react-multi-language";

class SectionElevenNoLang extends PureComponent {
  titleRef = null;
  dividerRef = null;
  item1 = null;
  item2 = null;
  item3 = null;
  item4 = null;
  item5 = null;
  descriptionRef = null;
  buttonRef = null;

  state = {
    active: 1
  }

  componentDidUpdate() {
    if (this.props.activeSection === 11) {
      this.onInit();
    }
  }

  onInit = () => {
    setTimeout(() => {
      TweenMax.to(
        this.dividerRef,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          ease: 'Power4.easeOut',
          opacity: 1
        }
      );
      TweenMax.to(
        this.descriptionRef,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          ease: 'Power4.easeOut',
          opacity: 1
        }
      );
      TweenMax.to(
        this.titleRef,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          opacity: 1,
          ease: 'Power4.easeOut',
        }
      );
    }, 300);

    setTimeout(() => {
      TweenMax.to(
        this.item1,
        0.7,
        {
          opacity: 1,
          transform: 'translate(0px 0px)',
          ease: 'Power4.easeOut'
        }
      );
    }, 50);
    setTimeout(() => {
      TweenMax.to(
        this.item2,
        0.7,
        {
          opacity: 1,
          transform: 'translate(0px 0px)',
          ease: 'Power4.easeOut'
        }
      );
    }, 100);
    setTimeout(() => {
      TweenMax.to(
        this.item3,
        0.7,
        {
          opacity: 1,
          transform: 'translate(0px 0px)',
          ease: 'Power4.easeOut'
        }
      );
    }, 150);
    setTimeout(() => {
      TweenMax.to(
        this.item4,
        0.7,
        {
          opacity: 1,
          transform: 'translate(0px 0px)',
          ease: 'Power4.easeOut'
        }
      );
    }, 200);
    setTimeout(() => {
      TweenMax.to(
        this.item5,
        0.7,
        {
          opacity: 1,
          transform: 'translate(0px 0px)',
          ease: 'Power4.easeOut'
        }
      );
    }, 250);

    TweenMax.to(
      this.buttonRef,
      .7,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        opacity: 1,
        ease: 'Power4.easeOut',
      }
    );
  }

  handleItemClick = (item) => {
    this.setState({
      active: item
    });
  }

  render() {
    const {
      title,
      description,
      button,
      items = []
    } = this.props.langProps;

    const text = [
      'Trade any crypto asset with Bill Trade even if the exchange you`re using doesn`t have it. Simply find it on the Bill Trade exchange, deposit funds and start trading.',
      'Automate your trading with Bill Trade. Delay is only 100 milliseconds to 1 second to copy.',
      'We keep all the information updated. Get live statistics on all the trades and asset details.',
      'Trader sets a price range so that every copier could proporportionally participate in a trade avoiding price pumps and loosing profit.',
      'Trader sets a price range so that every copier could proporportionally participate in a trade avoiding price pumps and loosing profit.',
      'Bill Trade makes sure that every user gets profits. The small-order execution system allows to keep the right balance between buy and sell orders.'
    ];

    return (
      <section
        className="p_main-page__billtrade-benefits"
        onMouseEnter={() => {
          this.props.changeCursorColor('black');
        }}
        onMouseLeave={() => {
          this.props.changeCursorColor('white');
        }}
      >
          <div className="container flex">
            <div
              className="item"
              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
              onMouseMove={this.props.onMouseMove}
            >
              {items.map((item, index) => (
                <div
                  onClick={() => this.handleItemClick(index)}
                  key={item}
                  ref={ref => {this[`item${index}`] = ref}}
                  className={`p_main-page__billtrade-benefits__list-item ${this.state.active === index ? 'active': '' }`}
                >
                  {item}
                </div>
              ))}
            </div>
            <div
              className="item"
            >
              <img ref={ref => this.dividerRef = ref} className="p_main-page__billtrade-benefits__divider" src="/bt-benefits-divider.svg"/>
              <div ref={ref => {this.titleRef = ref}} className="p_main-page__billtrade-benefits__title">
                <div>Bill Trade</div>
                <div>{title}</div>
              </div>
              <div ref={ref => {this.descriptionRef = ref}} className="p_main-page__billtrade-benefits__description">
                {text[this.state.active - 1]}
              </div>
              <a className="button-link" href="https://dash.billtrade.io">
                <Button refc={ref => {this.buttonRef = ref}}>
                  {button}
                </Button>
              </a>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/white-paper.svg)`,
              backgroundSize: 'cover',
              backgroundPosition: '100%',
              position: 'absolute',
              height: '20vh',
              width: '100%',
              right: 0,
              left: 0,
              bottom: -40
            }}
          />
      </section>
    )
  }
}

const SectionEleven = withLang({
  en: {
    title: `benefits`,
    description: `Trade any crypto asset with Bill Trade even if the exchange you're using doesn't have it. Simply find it on the Bill Trade exchange, deposit funds and start trading.`,
    button: `Open free account`,
    items: [
      'Copy trading via wallets',
      'Zero delay',
      'Real-time trading history',
      'The platform is free to use.',
      'Position entry slip control system',
      'Smart system of buy/sell balance',
    ],
  },
  ru: {
    title: `Преимущества`,
    description: `Торгуйте любым крипто активом с помощью Bill Trade, даже если у используемой вами биржи его нет. Просто найдите его на бирже Bill Trade, внесите средства и начните торговать.`,
    button: `Открыть бесплатный аккаунт`,
    items: [
      'Копирование сделок используя кошельки',
      'Нулевая задержка',
      'История торговли в реальном времени',
      'Бесплатное использование сервисов платформы',
      'Система регулирования проскальзывания входа в позицию',
      'Умная система покупки продажи активов'
    ],
  }
})(SectionElevenNoLang);

export default SectionEleven;
