import React from 'react';
import { Determinator, withLang } from "react-multi-language";

const FooterNoLang = ({
  langProps: {
    allRightsReserved
  }
}) =>  {
  return (
    <div className="p_main-page__footer_2">
      <div className="detection">You should not invest funds that you wouldn’t be able to lose. You should be advised from an independent and reputable licensed financial advisor, before deciding to trade to let you know all the risks associated with this practise (Trading). We shall not be responsible to any person or entity for any losses or damages particularly or in whole, related to any transactions (direct, indirect, special, consequential or incidental damages whatsoever). The previous good results of any trader on our platform  is not a sure indicator of his future performance. The content that you see on our platform is created by the users of BillTrade and does not contain advices or recommendations on behalf of BillTrade.</div>

      <div className="p_main-page__footer_2__menu-container">
        <div className="logo">
          <img src="/billtrade-logo-footer.svg" />
        </div>

        <div className="items">
          <a href="/Wiki.pdf" target="_blank">Wiki</a>
        </div>
        <div className="items">
          <a href="/Terms.pdf" target="_blank">Terms & Conditions</a>
        </div>
        <div className="items">
          <a href="Privacy.pdf" target="_blank">Privacy Policy</a>
          {/* <a href="" target="_blank">Cookie Policy</a> */}
        </div>
      </div>
      <div className="p_main-page__footer_2__line item">
        <div className="container">
          <div className="text">{allRightsReserved}</div>
        </div>
      </div>
    </div>
  )
}

const Footer = withLang({
  en: {
    title: `Feel free to contact us`,
    description: `We are glad to help you 24 hours a day, 7 days a week. Just write us a letter and we’ll answer you as soon as possible!`,
    emailPlaceholder: `Enter your E-mail`,
    messagePlaceholder: `Write your question here`,
    button: `Send`,
    copyTrading: `Copy trading`,
    allRightsReserved: `Bill Trade. 2019 all rights reserved`
  },
  ru: {
    title: `Связаться с нами`,
    description: 'Наша клиентская поддержка доступна 24/7. Для оперативного решения вопросов просто создайте заявку в личном кабинете.',
    emailPlaceholder: `Введите ваш E-mail`,
    messagePlaceholder: `Задайте нам вопрос`,
    button: `Отправить`,
    copyTrading: `Копирование сделок`,
    allRightsReserved: `Bill Trade. 2019 все права защищены`
  }
})(FooterNoLang);

export default Footer;