import React, { memo } from 'react'

import './style.scss';
import { useCursor } from '../../context/CursorContext';

function Logo() {
  const { onMouseEnter, onMouseLeave, onMouseMove } = useCursor();
  return (
    <div
      className="c_logo"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="billtrade" />
    </div>
  );
}

export default memo(Logo)
