import React from 'react'

function Cursor({
  color = 'white',
  type = 'scroll'
}) {
  if (type === 'next') {
    return (
      <svg className="cursor-icon" fill={color} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 492.004 492.004" space="preserve">
        <g>
          <g>
            <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
              c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
              c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
              c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"/>
          </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
      </svg>

    );
  }
  if (type === 'prev') {
    return (
      <svg className="cursor-icon" style={{
        transform: 'rotate(180deg)'
      }} fill={color} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 492.004 492.004" space="preserve">
      <g>
        <g>
          <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
            c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
            c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
            c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"/>
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>
      
    );
  }
  if (type === 'down') {
    return (
      <svg className="cursor-icon" style={{
        transform: 'rotate(90deg)'
      }} fill={color} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 492.004 492.004" space="preserve">
      <g>
        <g>
          <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
            c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
            c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
            c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"/>
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>
      
    );
  }
  if (type === 'scroll') {
    return (
      <svg
        className="cursor-icon"
        height="512pt"
        viewBox="-149 0 512 512.10667"
        width="512pt"
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
      >
        <path d="m106.71875 320.105469c-35.285156 0-64-28.714844-64-64 0-35.285157 28.714844-64 64-64s64 28.714843 64 64c0 35.285156-28.714844 64-64 64zm0-96c-17.640625 0-32 14.359375-32 32 0 17.644531 14.359375 32 32 32 17.644531 0 32-14.355469 32-32 0-17.640625-14.355469-32-32-32zm0 0"/><path d="m106.71875 512.105469c-5.757812 0-11.261719-2.324219-15.53125-6.546875l-86.570312-108.035156c-.46875-.574219-.894532-1.214844-1.277344-1.832032-2.21875-3.753906-3.2851565-7.554687-3.2851565-11.585937 0-11.753907 9.5781245-21.332031 21.3320315-21.332031h170.667969c11.753906 0 21.332031 9.578124 21.332031 21.332031 0 4.03125-1.066407 7.832031-3.285157 11.5625-.382812.640625-.8125 1.261719-1.28125 1.835937l-85.332031 106.667969c-5.503906 5.609375-11.007812 7.933594-16.769531 7.933594zm-63.316406-117.332031 63.382812 79.210937 63.273438-79.210937zm148.652344 0h.210937zm0 0"/><path d="m192.054688 149.441406h-170.667969c-11.753907 0-21.3320315-9.578125-21.3320315-21.335937 0-4.03125 1.0664065-7.828125 3.2851565-11.5625.382812-.640625.808594-1.257813 1.277344-1.832031l85.335937-106.667969c11.007813-11.179688 23.742187-9.8125 32.296875-1.367188l86.570312 108.035157c.46875.574218.898438 1.214843 1.28125 1.832031 2.21875 3.734375 3.285157 7.53125 3.285157 11.5625 0 11.757812-9.578125 21.335937-21.332031 21.335937zm-148.671876-32h126.65625l-63.382812-79.210937zm0 0"/>
      </svg>
    );
  }
}

export default Cursor
