import React, { PureComponent } from 'react';
import { TweenMax, Expo } from 'gsap';
import { withLang } from "react-multi-language";
import { Parallax, Background } from 'react-parallax';
import { useSpring, animated } from 'react-spring';

import Button from 'components/Button';
import Title from 'components/Title';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`

function Facts({
  refc,
}) {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 20, tension: 100, friction: 150 } }))
  return (
  <div
    className="three-facts"
    ref={refc}
    onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
  >
    <animated.div style={{ transform: props.xy.interpolate(trans4) }}>3Facts</animated.div>
  </div>
  )
}

class SectionTwoNoLang extends PureComponent {
  benefitsRefs = {
    title: null,
    bestItem1: null,
    bestItem2: null,
    bestItem3: null,
    button: null,
    threefacts: null,
    bt: null
  }

  componentDidUpdate() {
    if (this.props.activeSection === 2) {
      this.onInit();
    }
  }

  onInit = () => {
    TweenMax.to(
      this.benefitsRefs.title,
      .9,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );
    TweenMax.to(
      this.benefitsRefs.bt,
      .9,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );
    TweenMax.to(
      this.benefitsRefs.bestItem1,
      .9,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );
    TweenMax.to(
      this.benefitsRefs.bestItem2,
      .9,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );
    TweenMax.to(
      this.benefitsRefs.bestItem3,
      .9,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        ease: 'Power4.easeOut',
        opacity: 1
      },
    );
    TweenMax.to(
      this.benefitsRefs.button,
      .9,
      {
        transform: 'translateX(0px) translateY(0px) scale(1)',
        opacity: 1,
        ease: 'Power4.easeOut',
      }
    );

    TweenMax.to(
      this.benefitsRefs.threefacts,
      .9,
      {
        transform: 'translate(-50px, 25px)'
      }
    );
  }

  render() {
    const {
      title1,
      billtrade,
      item1,
      item2,
      item3,
      button
    } = this.props.langProps;

    return (
      <section className="p_main-page__benefits">
        <img ref={ref => this.benefitsRefs.bt = ref} src="/benefits-bt.svg" className="p_main-page__benefits__bt" />
        
        <div className="container">
          <div
            className="fbody"
          >
            <div className="fleft p_main-page__benefits__title" ref={ref => { this.benefitsRefs.title = ref }}>
              <Title style={{ textAlign: 'right' }}>
                {title1}
              </Title>
              <Title style={{ textAlign: 'right' }}>
                {billtrade}
              </Title>
            </div>
            <div className="fright"></div>
          </div>

          <div className="fbody">
            <div className="fleft"></div>
            <div className="fright p_main-page__benefits__best-container">
              <div
                ref={ref => { this.benefitsRefs.bestItem1 = ref }}
                className="best-item"
              >
                {item1}
              </div>
              <div
                ref={ref => { this.benefitsRefs.bestItem2 = ref }}
                className="best-item"
              >
                {item2}
              </div>
              <div
                ref={ref => { this.benefitsRefs.bestItem3 = ref }}
                className="best-item"
              >
                {item3}
              </div>
              <a className="button-link" href="https://dash.billtrade.io" ref={ref => this.benefitsRefs.button = ref}>
                <Button>
                  {button}
                </Button>
              </a>

              <Facts refc={ref => {this.benefitsRefs.threefacts = ref}} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const SectionTwo = withLang({
  en: {
    title1: `What can you get`,
    billtrade: `with Bill Trade?`,
    item1: `Best traders in the industry`,
    item2: `Best security for your assets`,
    item3: `Best additional income`,
    button: `Open free account`
  },
  ru: {
    title1: `Что вы можете`,
    billtrade: `Bill Trade?`,
    item1: `Лучшие трейдеры в индустрии`,
    item2: `Безопасность ваших активов`,
    item3: `Лучший дополнительный доход`,
    button: `Узнать больше`
  }
})(SectionTwoNoLang);

export default SectionTwo;
