import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import './App.scss';
import MainPage from './pages/MainPage';
import CursorContext from './context/CursorContext';
import LanguageContext from './context/LanguageContext';
import Mobile from './pages/MainPage/Mobile/Mobile';
import ReactResizeDetector from 'react-resize-detector';

function App() {
  return (
    <ReactResizeDetector handleWidth handleHeight>
      {({ width, height }) => {
        if (width > 900) {
          return (
            <div className="App">
                <ParallaxProvider>
                  <LanguageContext>
                    <CursorContext>
                      <MainPage />
                    </CursorContext>
                  </LanguageContext>
                </ParallaxProvider>
            </div>
          );
        } else {
          return (
            <LanguageContext>
              <Mobile />
            </LanguageContext>
          );
        }
      }}
    </ReactResizeDetector>
  );
}

export default App;
