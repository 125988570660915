import React, { PureComponent } from 'react'

export default class SectionTen extends PureComponent {
  state = {
    active: 0
  }

  next = () => {
    if (this.state.active === 0) {
      this.setState({ active: 1 });
    } else {
      this.setState({ active: 0 });
    }
  }


  render() {
    const data = [
      {
        name: 'coin',
        logo: 'https://lh3.googleusercontent.com/proxy/iSHVorAz7X2bLo8zLfVGocbxbaBrAfx_GPeOH-pnLkKl4H5cQTxaqlAuxtM3oaN6VVIqF3Xzp1ZdCWdDiHAP6DOVvVcICf1g',
        description: 'Выход BillTrade в открытый доступ не только дает своим клиентам возможность заработка, но и изменит лицо всей отрасли до неузнаваемости.'
      },
      {
        name: 'coingogo',
        logo: 'https://m.coingogo.com/wap/image/login_logo.png',
        description: 'BillTrade是一種投資工具，旨在為行業中的頂級交易員和尋求被動創收機會的投資者提供社交平台。 該平台最近成功完成了封閉的贝塔測試階段，並向用戶免費提供對其上運營的最佳交易者的訂閱。 這些訂閱將使新用戶有機會在可靠且盈利的交易模型的基礎上開始賺錢。'
      },
    ]
    return (
      <section className="p_main-page__media-about-us">
        <div className="container flex">
          <div className="item">
          <div className="p_main-page__media-about-us__title">
              Media <br />
              about us
            </div>
          </div>
          <div className="item"></div>
        </div>

        <div className="p_main-page__media-about-us__slider-container flex">
          <div className="item image">
            <img style={{ width: 250 }} src={[data[this.state.active].logo]} />
          </div>
          <div className="item">
            <div className="text">
              {[data[this.state.active].description]}
            </div>
            <div className="paginator flex" onClick={this.next}>
              <div className="item"><img src={process.env.PUBLIC_URL + '/arrow-left.svg'} alt=""/></div>
              <div className="item">
                <span>{this.state.active + 1}</span>
                /2
              </div>
              <div className="item"><img src={process.env.PUBLIC_URL + '/arrow-right.svg'} alt=""/></div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
