import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './style.scss';

import { useCursor } from '../../context/CursorContext';

const Button =  ({
  children,
  bodyStyle,
  textStyle,
  refc,
  onClick = () => {}
}) => {
  const { onMouseEnter, onMouseLeave, onMouseMove } = useCursor();
  return (
    <div
      onClick={onClick}
      className="c_button"
      ref={refc}
      style={bodyStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      <div
        className="c_button__text"
        style={textStyle}
      >
        {children}
      </div>
    </div>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  bodyStyle: PropTypes.object,
  textStyle: PropTypes.object,
  refc: PropTypes.any
}

export default Button
