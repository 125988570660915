import React, { PureComponent } from 'react'
import Navigation from "react-sticky-nav";

import Logo from '../Logo';
import Menu from '../Menu';

import './style.scss';

export default class Nav extends PureComponent {
  render() {
    return (
      <Navigation
        className="c_nav"
      >
        <div className="container">
          <Logo
          />
          <div
            className="c_nav__menu"
          >
            <Menu />
          </div>
        </div>
      </Navigation>
    );
  }
}

Nav.propTypes = {

}

