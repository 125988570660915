import React, { useEffect, PureComponent } from "react";
import PropTypes from 'prop-types';
import { MultiLang } from "react-multi-language";

const allowedLangs = ['en', 'ru', 'ar', 'fr'];

export const Context = React.createContext({
  lang: 'en',
  changeLang: () => {}
});

export const useLang = () => React.useContext(Context);

export default class LanguageContext extends PureComponent {
  state = {
    lang: 'en'
  }

  handleLangChange = (lang) => {
    if (allowedLangs.indexOf(lang) !== -1) {
      this.setState({
        lang
      });
    }
  }

  render() {
    const {
      handleLangChange,
      state,
      props
    } = this;

    const { children } = props;

    const { lang } = state;

    return (
      <Context.Provider value={{ lang, changeLang: handleLangChange }}>
        <>
          {children}
          <MultiLang lang={this.state.lang}/>
        </>
      </Context.Provider>
    )
  }
}

LanguageContext.propTypes = {
  children: PropTypes.object.isRequired,
};
