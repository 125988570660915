import React from 'react'
import PropTypes from 'prop-types';
import { Determinator, withLang } from "react-multi-language";

import './style.scss';
import Button from '../Button';

function TopMemberNoLang({
  langProps,
  isRed = false
}) {
  return (
    <div className={`c_top-member ${isRed ? 'red' : 'green'}`}>
      <div className="looking">{langProps.looking}</div>
      <div className="avatar">
        <div className="image">
          <div className="button">
            {langProps.copy}
          </div>
        </div>
      </div>
      <div className="stats">
        <div className="title">Trader</div>
        <div className="rank">8.2</div>
        <div className="subitem">
          <div className="label">{langProps.allTimeProfit}:</div>
          <div className="amount">42.3%</div>
        </div>
        <div className="subitem">
          <div className="label">{langProps.dailyProfit}:</div>
          <div className="amount">42.3%</div>
        </div>
        <div className="subitem">
          <div className="label">{langProps.deposit}:</div>
          <div className="amount">$124,245.22</div>
        </div>
      </div>
      <div className="chart">
      <svg width="514.521" height="139.516" viewBox="0 0 514.521 139.516">
        <path d="M632.879,340.351l-56.923,32.1H1087.98V250.379L1049,273.367l-47.389-40.428-20.19,30.916-12.337-13.476L939.644,302.7l-38.7-69.759-47.669,99.484L782.056,302.7l-82.44,49.939Z" transform="translate(-574.077 -232.939)" fill="#8c8c8d" opacity="0.5"/>
        <path d="M602.009,341.57l-27.478,30.884h514.521V239.444l-77.108,20.706-43.18,67.733-77.949-88.439L799.126,341.57l-99.82-30.883-59.724,51.59Z" transform="translate(-574.531 -233.124)" fill={isRed ? '#FF4500' : '#7fffd4'} opacity="0.5"/>
      </svg>
      </div>
    </div>
  )
}

TopMemberNoLang.propTypes = {

}

const TopMember = withLang({
  en: {
    allTimeProfit: 'All time profit',
    dailyProfit: 'Monthly profit',
    deposit: 'Deposit, USD',
    looking: 'looking',
    copy: 'Subscribe!'
  },
  ru: {
    allTimeProfit: 'Доход за всё время',
    dailyProfit: 'Дневной доход',
    deposit: 'Депозит, USD',
    looking: 'looking',
    copy: 'Копировать!'
  }
})(TopMemberNoLang);

export default TopMember;
 

