import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

function Title({
  children = '',
  style = {},
}) {
  return (
    <div style={style} className="c_title">
      {children}
    </div>
  )
}

Title.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object
}

export default Title;
