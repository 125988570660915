
import React from 'react'
import PropTypes from 'prop-types';

import './style.scss';

const Header = (props) => (
  <div className="c_header">
    <div className="logo">
      <img src={process.env.PUBLIC_URL + '/mobile/Logo.svg'} />
    </div>
    <div onClick={props.toggleMenu} className="hamburger">
      <img src={process.env.PUBLIC_URL + '/mobile/Menu.svg'} />
    </div>
  </div>
);

Header.propTypes = {
  toggleMenu: PropTypes.func
}

export default Header
