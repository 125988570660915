import React, { PureComponent } from 'react'
import TouchCarousel from 'react-touch-carousel'
import { Determinator, withLang } from "react-multi-language";
import TopMember from '../../components/TopMember/TopMember';
import NonPassiveTouchTarget from './NonPassiveTouchTarget';
import touchWithMouseHOC from './touchWithMouseHOC';
import { VizSensor } from 'react-visibility-sensor';
const cardSize = 170
const cardPadCount = 2

function CarouselContainer (props) {
  const {cursor, carouselState, ...rest} = props
  const translateY = (cursor - cardPadCount) * cardSize
  return (
    <NonPassiveTouchTarget className='carousel-container'>
      <NonPassiveTouchTarget
        className='carousel-track'
        style={{transform: `translate3d(0, ${translateY}px, 0)`}}
        {...rest}
      />
    </NonPassiveTouchTarget>
  )
}

const Container = touchWithMouseHOC(CarouselContainer)

class SectionEightNoLang extends PureComponent {
  state = {
    currentList: 'traders'
  };
  
  data = [
    <div className="member-placeholder"></div>,
    <TopMember/>,
    <TopMember/>,
    <TopMember/>,
    <TopMember/>,
    <TopMember/>,
    <TopMember isRed />,
    <TopMember isRed />,
    <TopMember isRed />,
    <TopMember isRed />,
    <TopMember isRed />,
  ];

  setTraders = (isVisible) => {
    if (isVisible && this.state.currentList !== 'traders') {
      this.setState({
        currentList: 'traders'
      });
    }
  }
  setUsers = (isVisible) => {
    if (isVisible && this.state.currentList !== 'users') {
      this.setState({
        currentList: 'users'
      });
    }
  }

  renderCard = (index, modIndex, cursor) => {
    const item = this.data[modIndex];

    if (cursor < -5) {
      this.setUsers(true);
    } else {
      this.setTraders(true);
    }

    return (
      <div
        key={index}
        className='carousel-card'
        data-index={index}
        data-modindex={modIndex}
      >
        {item}
      </div>
    )
  }

  render() {
    const {
      title1,
      title2,
      subtitleTraders
    } = this.props.langProps;

    const {
      currentList
    } = this.state;

    return (
      <section className={`p_main-page__top-members ${currentList === 'traders' ? 'green' : 'red'}`}>
      <div className="container flex col">
        <div className="p_main-page__top-members__title item flex">
          <div className="item text">
            <div>
              {title1}
            </div>
            <div>
              {title2}
            </div>
          </div>
          <div className="item placeholder"></div>
        </div>
        <div className="p_main-page__top-members__body item flex">
          <div className="item subtitle">
            <div className="text">{subtitleTraders}</div>
          </div>
          <React.StrictMode>
            <TouchCarousel
              component={Container}
              cardSize={cardSize}
              cardCount={this.data.length}
              cardPadCount={cardPadCount}
              autoplay={1500}
              vertical
              renderCard={this.renderCard}
            />
          </React.StrictMode>
        </div>
      </div>
    </section>
    )
  }
}

const SectionEight = withLang({
  en: {
    title1: 'Top',
    title2: 'Members',
    subtitleTraders: 'Our best traders',
    subtitleUsers: 'Our best users',
    allTimeProfit: 'All time profit',
    dailyProfit: 'Monthly profit',
    deposit: 'Deposit, USD',
    looking: 'looking'
  },
  ru: {
    title1: 'Лучшие',
    title2: 'Клиенты',
    subtitleTraders: 'Лучшие трейдеры',
    subtitleUsers: 'Лучшие пользователи',
    allTimeProfit: 'Общий доход',
    dailyProfit: 'Дневной доход',
    deposit: 'Депозит, USD',
    looking: 'looking'
  }
})(SectionEightNoLang);

export default SectionEight;
 