import React, { useEffect, PureComponent } from "react";
import PropTypes from 'prop-types';
import { TweenMax, Power2 } from 'gsap';
import Cursor from '../icons/cursor';
import {
  Tween,
  TweenLite
} from 'gsap/gsap-core';

export const Context = React.createContext({
  onMouseEnter: null,
  onMouseLeave: null,
  onMouseMove: null,
  changeCursorType: null,
  changeCursorColor: null,
});

export const useCursor = () => React.useContext(Context);

export default class CursorContext extends PureComponent {
  state = {
    cursorColor: 'white',
    cursorIcon: null
  }

  active = false;
  mouse = { x: 0, y: 0 };
  pos = { x: 0, y: 0 };
  ratio = 0.5;

  ball = null;
  cursorIconRef = null;

  componentDidMount() {
    
    TweenLite.set(this.ball, { xPercent: -50, yPercent: -50 });

      const updatePosition = () => {
        if (!this.active) {
            this.pos.x += (this.mouse.x - this.pos.x) * this.ratio;
            this.pos.y += (this.mouse.y - this.pos.y) * this.ratio;
    
            TweenLite.set(this.ball, { x: this.pos.x, y: this.pos.y });
        }
    }

    const mouseMove = (e) => {
        this.mouse.x = e.pageX;
        this.mouse.y = e.pageY;
        updatePosition();
    }

    window.addEventListener('mousemove', mouseMove, false);
  }

  onMouseEnter = (e) => {
    this.hideCursor();
    // TweenMax.to(e, 0.3, { scale: 2 });
    TweenMax.to(this.ball, 0.4, { scale: 3 });
    this.active = true;
  }

  onMouseLeave = (e) => {
    this.showCursor();
    TweenMax.to(e.target, 0.3, { x: 0, y: 0 });
    TweenMax.to(this.ball, 0.4, { scale: 1 });
    this.active = false;
  }

  onMouseMove = (e) => {
    // this.parallaxCursor(e, e.currentTarget, 3);
    // this.callParallax(e);
    // TweenMax.to(e.target, 0.3, { x: 0, y: 0 });
    this.active = false;
  }

  parallaxCursor = (e, parent, movement) => {
    var rect = parent.getBoundingClientRect();
    var relX = e.pageX - rect.left;
    var relY = e.pageY - rect.top;

    this.pos.x = rect.left + rect.width / 2 + (relX - rect.width / 2) / movement;
    this.pos.y = rect.top + rect.height / 2 + (relY - rect.height / 2) / movement;

    TweenMax.to(this.ball, 0.2, { x: this.pos.x, y: this.pos.y });
  }

  parallaxIt = (e, parent, target, movement) => {
      var boundingRect = parent.getBoundingClientRect();
      var relX = e.pageX - boundingRect.left;
      var relY = e.pageY - boundingRect.top;
  
      TweenMax.to(target, 0.3, {
          x: (relX - boundingRect.width / 2) / boundingRect.width * movement,
          y: (relY - boundingRect.height / 2) / boundingRect.height * movement,
          ease: Power2.easeOut
      });
  }

  callParallax = (e) => {
    this.parallaxIt(e, e.currentTarget, e.target, 6);
  }

  hideCursor = () => {
    TweenMax.to(
      this.cursorIconRef,
      .1,
      {
        opacity: 0,
        transform: 'scale(0)'
      }
    );
  }

  showCursor = () => {
    TweenMax.to(
      this.cursorIconRef,
      .2,
      {
        opacity: 1,
        transform: 'scale(1)'
      }
    );
  }

  changeCursorType = (type = 'scroll') => {
    this.setState({
      cursorIcon: type
    });
  }

  changeCursorColor = (color = 'white') => {
    this.setState({
      cursorColor: color
    });
  }
  
  render() {
    const {
      children
    } = this.props;

    return (
      <Context.Provider value={{
        onMouseEnter: this.onMouseEnter,
        onMouseLeave: this.onMouseLeave,
        onMouseMove: this.onMouseMove,
        changeCursorType: this.changeCursorType,
        changeCursorColor: this.changeCursorColor
      }}>
        <>
          <div className="magic-cursor">
            <div
              ref={ref => {this.ball = ref}}
              className="ball"
              style={{
                border: `1px solid ${this.state.cursorColor}`
              }}
            >
              <div
                className="cursor-icon-container"
                ref={ref => {this.cursorIconRef = ref}}
              >
                {this.state.cursorIcon ? <Cursor color={this.state.cursorColor} type={this.state.cursorIcon} /> : ''}
              </div>
            </div>
          </div>
          {children}
        </>
      </Context.Provider>
    )
  }
}

CursorContext.propTypes = {
  children: PropTypes.object.isRequired,
};
