import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { Determinator } from "react-multi-language";

import './style.scss';

import { useCursor } from '../../context/CursorContext';
import { useLang } from '../../context/LanguageContext';

function Menu() {
  const { onMouseEnter, onMouseLeave, onMouseMove } = useCursor();
  const { changeLang, lang } = useLang();

  return (
    <ul className="c_menu">
      <li
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        <a href="https://dash.billtrade.io" target="_blank">
          <Determinator>
            {{
              en: `Login`,
              ru: `Войти`,
              ar: ``,
              fr: ``
            }}
          </Determinator>
        </a>
      </li>
      <li
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        <a href="https://dash.billtrade.io" target="_blank">
        <Determinator>
          {{
            en: `Sign up`,
            ru: `Зарегистрироваться`,
            ar: ``,
            fr: ``
          }}
        </Determinator>
        </a>
      </li>
    </ul> 
  )
}

Menu.propTypes = {

}

export default memo(Menu);
