import React, { PureComponent, useState } from 'react';
import Nav from '../../components/Nav';
import Button from '../../components/Button';
import { useTrail, animated, useSpring } from 'react-spring';
import { TweenMax } from 'gsap';
import { withLang } from "react-multi-language";

const scale1 = (x) => `scale(${x})`;

const items = ['Veni', 'Vidi', 'Copy'];
const config = { mass: 3, tension: 500, friction: 60 };

const VeniVidiCopy = (toggle = true) => {
  const trail = useTrail(items.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: 1,
    from: { opacity: 0, x: 10 },
  });

  return (
    <div>
      {trail.map(({ x, ...rest }, index) => (
        <animated.div
          key={items[index]}
          className="p_main-page__hero__moto__container trails-text"
          style={{ ...rest }}>
          <animated.div style={{ transform: x.interpolate(scale1) }}>{items[index]}</animated.div>
        </animated.div>
      ))}
    </div>
  )
}

function BT() {
  return (
    <svg width="662.965" height="629.833" viewBox="0 0 662.965 629.833">
      <defs>
        <linearGradient id="bt-filter" x1="0.5" x2="0.5" y2="1.546" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#202020"/>
          <stop offset="1" stopColor="#6a6a6a"/>
        </linearGradient>
      </defs>
      <path d="M-.058,629.813v-490.7H193.418q73.606,0,117.769,37.5t44.163,102q0,61.687-45.565,97.438,61.688,36.453,61.688,110.057,0,65.193-44.864,104.449T206.737,629.813Zm125.479-112.16h77.811q18.927,0,30.844-11.567t11.917-29.793q0-18.927-11.917-30.143t-30.844-11.216H125.421Zm0-189.27h64.492q16.824,0,28.391-10.165t11.567-28.391q0-17.526-11.217-28.04t-28.741-10.515H125.421Zm290.734,162.3V121.253H294.882V-.02H662.908V121.253H541.634V490.68Z" transform="translate(0.058 0.02)" fill="url(#bt-filter)"/>
    </svg>
  )
}

class SectionOneNoLang extends PureComponent {
  componentDidMount() {
    setTimeout(() => {
      TweenMax.to(
        this.heroRefs.bt1,
        1,
        {
          opacity: 1,
          transform: 'scale(1) translate(0px, 0px)',
          ease: 'Power4.easeOut'
        }
      );
    }, 300);

    TweenMax.to(
      this.floatingBackgroundWhite,
      2,
      {
        backgroundImage: 'linear-gradient(160deg, rgba(28,28,28,1) 25%, rgba(103,103,103,1) 120%, rgba(103,103,103,1) 200%)',
      }
    );
    setTimeout(() => {
      TweenMax.to(
        this.heroRefs.labelVertical,
        1.5,
        {
          filter: 'blur(0px)',
          opacity: 1
        }
      );
      TweenMax.to(
        this.heroRefs.divider,
        .5,
        {
          filter: 'blur(0px)',
          opacity: 1
        }
      );
      TweenMax.to(
        this.button,
        .4,
        {
          transform: 'translate(0px, 0px)',
          opacity: 1,
          ease: 'Power4.easeOut'
        }
      );
    }, 1);

    TweenMax.to(
      this.subtitle,
      .5,
      {
        color: '#ffffff',
        opacity: 1
      }
    );
  }

  heroRefs = {
    bt1: null,
    divider: null,
    labelVertical: null
  }

  floatingBackgroundWhite = null;
  subtitle = null;
  button = null;

  render() {
    const {
      vertical,
      subtitle,
      button
    } = this.props.langProps;

    return (
      <section className="p_main-page__hero">
        <div ref={ref => {this.floatingBackgroundWhite = ref}} className="floating-background-white"></div>
        <Nav></Nav>
        <div className="container col">
          <div className="trails-mai p_main-page__hero__moto">
            <VeniVidiCopy />

            <div ref={ref => {this.heroRefs.divider = ref}} className="divider"></div>
            <div className="label-vertical"  ref={ref => {this.heroRefs.labelVertical = ref}}>
              <span className="dot"></span>
              {vertical}
            </div>
          </div>

          <div
            ref={ref => {this.heroRefs.bt1 = ref}}
            className="bt-container"
          >
            <BT />
          </div>
          
          <div className="p_main-page__hero__start-now">
            <div ref={ref => {this.subtitle = ref}} className="description-container">
              {subtitle}
            </div>
            <a
              href="https://dash.billtrade.io"
              className="button-container"
            >
              <Button
                bodyStyle={{
                  filter: 'blur(0px)',
                  opacity: 0
                }}
                refc={ref => {this.button = ref}}
              >
                {button}
              </Button>
            </a>
            <div className="placeholder">
              <div className="text">The services of the platform are working privately, those who want to use them are welcome to <b>sign up</b> and <b>submit the application</b></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}


const SectionOne = withLang({
  en: {
    vertical: `The best traders choose Bill Trade`,
    subtitle: `Copy the trades of successful traders`,
    button: `Start Now`
  },
  ru: {
    vertical: `Лучшие трейдеры выбирают Bill Trade`,
    subtitle: `Копируйте сделки успешных трейдеров`,
    button: `Подключиться`
  }
})(SectionOneNoLang);

export default SectionOne;