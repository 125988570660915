import React, { PureComponent } from 'react';
import { FullPage, Slide } from 'react-full-page';
import { Determinator, withLang } from "react-multi-language";
import { useCursor } from 'context/CursorContext';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import { SectionFourOne, SectionFourTwo, SectionFourThree } from './SectionFour';
import SectionFive from './SectionFive';
import SectionSix from './SectionSix';
import SectionSeven from './SectionSeven';
import SectionEight from './SectionEight';
import SectionNine from './SectionNine';
import SectionTen from './SectionTen';
import SectionEleven from './SectionEleven';
import SectionTwelve from './SectionTwelve';
import Footer from './Footer';
import { TweenMax } from 'gsap';

import { Context as CursorContext } from '../../context/CursorContext';

import Navigation from '../../components/Navigation';

import './media2000.scss';
import './media1600.scss';
import './media1280.scss';
import './media900.scss';
import './media640.scss';

const Skip = ({ onClick }) => {
  const { onMouseEnter, onMouseLeave, onMouseMove, changeCursorColor } = useCursor();

  return (
    <div
      className="c_skip-icon"
      onMouseEnter={(e) => { onMouseEnter(e); changeCursorColor('black'); }}
      onMouseLeave={(e) => { onMouseLeave(e); changeCursorColor('white'); }}
      onMouseMove={onMouseMove}
      onClick={onClick}
    >
      <div className="c_skip-icon__tip">
        <Determinator>
          {{
            en: 'Skip',
            ru: 'Пропустить'
          }}
        </Determinator>
      </div>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 490.672 490.672"
        space="preserve"
        className="icon"
      >
        <g>
          <g>
            <path d="M466.221,237.787L231.555,3.12c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l227.115,227.136
              L216.451,472.453c-4.16,4.16-4.16,10.923,0,15.083c2.091,2.091,4.821,3.136,7.552,3.136c2.731,0,5.461-1.045,7.552-3.115
              l234.667-234.667C470.381,248.709,470.381,241.968,466.221,237.787z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M274.221,237.787L39.555,3.12c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l227.115,227.136
              L24.451,472.453c-4.16,4.16-4.16,10.923,0,15.083c2.091,2.091,4.821,3.136,7.552,3.136c2.731,0,5.461-1.045,7.552-3.115
              l234.667-234.667C278.381,248.709,278.381,241.968,274.221,237.787z"/>
          </g>
        </g>
      </svg>
    </div>
  );
}

const Divider = ({ currentPage = 0, refc }) => {
  const { onMouseEnter, onMouseLeave, onMouseMove, changeCursorColor } = useCursor();

  return (
    <svg
      ref={refc}
      className="advantages-divider"
      width="73"
      height="535.5"
      viewBox="0 0 73 535.5"
      onMouseEnter={(e) => { onMouseEnter(e); changeCursorColor('black'); }}
      onMouseLeave={(e) => { onMouseLeave(e); changeCursorColor('white'); }}
      onMouseMove={onMouseMove}
    >
      <g transform="translate(-705 -2268)">
        <g transform="translate(4 4.5)">
          <text id="Trade_without_barriers" transform="translate(744 2395.5) rotate(90)" fill="#9b9b9d" fontSize="14" fontFamily="Helvetica" letterSpacing="0.35em"><tspan x="0" y="0">
            <Determinator>
              {{
                ru: `ТОРГОВЛЯ БЕЗ БАРЬЕРОВ`,
                en: `TRADE WITHOUT BARRIERS`
              }}  
            </Determinator>  
          </tspan></text>
        </g>
        <g id="Group_2591" transform="translate(0 -19.5)">
          <path id="Path_3931" d="M0,0V500" transform="translate(741.5 2287.5)" fill="none" stroke="#9b9b9d" strokeWidth="1"/>
          <g id="Group_2590" transform="translate(-230 -969)">
            <circle id="Ellipse_343" cx="5" cy="5" r="5" transform="translate(935 3782)" fill={currentPage === 4 ? '#393a3e' : '#9b9b9d'}/>
            <circle id="Ellipse_344" cx="5" cy="5" r="5" transform="translate(967 3782)" fill={currentPage === 5 ? '#393a3e' : '#9b9b9d'}/>
            <circle id="Ellipse_345" cx="5" cy="5" r="5" transform="translate(998 3782)" fill={currentPage === 6 ? '#393a3e' : '#9b9b9d'}/>
          </g>
        </g>
      </g>
    </svg>
  );
}


class MainPage extends PureComponent {
  state = {
    scrollLocked: false,
    scrollMode: 'full-page',
    activeSection: 1,
    overflow: 'unset',
    sectionFourVisited: false,
    width: window.innerWidth, height: window.innerHeight
  };

  fullPageRef = React.createRef();
  section41Ref = null;
  section42Ref = null;
  section43Ref = null;
  advantagesRefs41 = {
    title1: null,
    subtitle1: null,
    title2: null,
    subtitle2: null,
    mainTitle: null,
    divider: null
  }
  advantagesRefs42 = {
    title1: null,
    subtitle1: null,
    title2: null,
    subtitle2: null,
    mainTitle: null,
    divider: null
  }
  advantagesRefs43 = {
    title1: null,
    subtitle1: null,
    title2: null,
    subtitle2: null,
    mainTitle: null,
    divider: null
  }

  onControlsClick = (slide = 1) => {
    this.fullPageRef.current.scrollToSlide(slide);
  }
  
  setCurrentSection = (activeSection) => {
    if (this.state.activeSection !== activeSection) {
        this.setState({
          activeSection
        });
    }
  }

  beforeScroll = ({ from, to }) => {
    console.log(from);
    if (from === 3) {
      TweenMax.to(
        this.advantagesRefs41.mainTitle,
        0.2,
        {
          opacity: 0,
          transform: 'translate(-20px, -30px)',
          ease: 'Power4.easeOut'
        }
      );
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs41.title1,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
        TweenMax.to(
          this.advantagesRefs41.subtitle1,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
      }, 100);
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs41.divider,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
        TweenMax.to(
          this.advantagesRefs41.title2,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
        TweenMax.to(
          this.advantagesRefs41.subtitle2,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
      }, 200);
    }

    if (from === 4) {
      TweenMax.to(
        this.advantagesRefs42.mainTitle,
        0.2,
        {
          opacity: 0,
          transform: 'translate(-20px, -30px)',
          ease: 'Power4.easeOut'
        }
      );
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs42.title1,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
        TweenMax.to(
          this.advantagesRefs42.subtitle1,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
      }, 100);
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs42.divider,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
        TweenMax.to(
          this.advantagesRefs42.title2,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
        TweenMax.to(
          this.advantagesRefs42.subtitle2,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
      }, 200);
    }
    if (from === 5) {
      TweenMax.to(
        this.advantagesRefs43.mainTitle,
        0.2,
        {
          opacity: 0,
          transform: 'translate(-20px, -30px)',
          ease: 'Power4.easeOut'
        }
      );
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs43.title1,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
        TweenMax.to(
          this.advantagesRefs43.subtitle1,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
      }, 100);
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs43.divider,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
        TweenMax.to(
          this.advantagesRefs43.title2,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
        TweenMax.to(
          this.advantagesRefs43.subtitle2,
          0.2,
          {
            opacity: 0,
            transform: 'translate(-20px, -30px)',
            ease: 'Power4.easeOut'
          }
        );
      }, 200);
    }
  }

  afterScroll = ({from, to}) => {
    this.setCurrentSection(to + 1);

    if (to === 3) {
      TweenMax.to(
        this.advantagesRefs41.mainTitle,
        0.6,
        {
          opacity: 1,
          transform: 'translate(0px, 0px)',
          ease: 'Power4.easeIn'
        }
      );
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs41.title1,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
        TweenMax.to(
          this.advantagesRefs41.subtitle1,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
      }, 100);
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs41.divider,
          0.8,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
        TweenMax.to(
          this.advantagesRefs41.title2,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
        TweenMax.to(
          this.advantagesRefs41.subtitle2,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
      }, 200);
    }
    if (to === 4) {
      TweenMax.to(
        this.advantagesRefs42.mainTitle,
        0.6,
        {
          opacity: 1,
          transform: 'translate(0px, 0px)',
          ease: 'Power4.easeIn'
        }
      );
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs42.title1,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
        TweenMax.to(
          this.advantagesRefs42.subtitle1,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
      }, 100);
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs42.divider,
          0.8,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
        TweenMax.to(
          this.advantagesRefs42.title2,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
        TweenMax.to(
          this.advantagesRefs42.subtitle2,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
      }, 200);
    }
    if (to === 5) {
      TweenMax.to(
        this.advantagesRefs43.mainTitle,
        0.6,
        {
          opacity: 1,
          transform: 'translate(0px, 0px)',
          ease: 'Power4.easeIn'
        }
      );
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs43.title1,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
        TweenMax.to(
          this.advantagesRefs43.subtitle1,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
      }, 100);
      setTimeout(() => {
        TweenMax.to(
          this.advantagesRefs43.divider,
          0.8,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
        TweenMax.to(
          this.advantagesRefs43.title2,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
        TweenMax.to(
          this.advantagesRefs43.subtitle2,
          0.6,
          {
            opacity: 1,
            transform: 'translate(0px, 0px)',
            ease: 'Power4.easeIn'
          }
        );
      }, 200);
    }
  }

  render() {
    const {
      overflow,
      scrollMode
    } = this.state;

    const {
      section41,
      section42,
      section43,
    } = this.props.langProps;

    const isFourActive = this.state.activeSection === 4 || this.state.activeSection === 5 || this.state.activeSection === 6;

      return (
        <CursorContext.Consumer>
          {
            ({
              changeCursorType,
              changeCursorColor,
              onMouseEnter,
              onMouseLeave,
              onMouseMove
            }) => (
              <div
                className="p_main-page"
                style={{
                  overflow
                }}
              >
                <Navigation
                  active={this.state.activeSection}
                  onClick={this.onControlsClick}
                />
                {isFourActive ? <Skip onClick={() => this.onControlsClick(6)} /> : ''}
                <FullPage
                  ref={this.fullPageRef}
                  duration={500}
                  afterChange={this.afterScroll}
                  beforeChange={this.beforeScroll}
                  scrollMode={scrollMode}
                >
                  <Slide>
                    <SectionOne activeSection={this.state.activeSection} />
                  </Slide>
                  <Slide>
                    <SectionTwo activeSection={this.state.activeSection} />
                  </Slide>
                  <Slide>
                    <SectionThree activeSection={this.state.activeSection} />
                  </Slide>

                  <Slide>
                    <section
                      className="p_main-page__advantages__main"
                      onMouseEnter={() => changeCursorColor('black')}
                      onMouseLeave={() => changeCursorColor('white')}
                    >
                      <div className="container">
                        <img ref={ref => this.advantagesRefs41.divider = ref} src={process.env.PUBLIC_URL + 'divider41.svg'} className="advantages-divider" />
                        <div className="flex">
                          <div className="item">

                            <div className="p_main-page__advantages__main__stacked-item">
                              <div
                                className="p_main-page__advantages__main__stacked-item__title"
                                ref={ref => {this.advantagesRefs41.title1 = ref}}
                                dangerouslySetInnerHTML={{ __html: section41.top.title }}
                              />
                              <div
                                className="p_main-page__advantages__main__stacked-item__description"
                                ref={ref => {this.advantagesRefs41.subtitle1 = ref}}
                                dangerouslySetInnerHTML={{ __html: section41.top.subtitle }}
                              />
                            </div>

                            <div className="p_main-page__advantages__main__stacked-item">
                              <div
                                className="p_main-page__advantages__main__stacked-item__title"
                                ref={ref => {this.advantagesRefs41.title2 = ref}}
                                dangerouslySetInnerHTML={{ __html: section41.bottom.title }}
                              />
                              <div
                                className="p_main-page__advantages__main__stacked-item__description"
                                ref={ref => {this.advantagesRefs41.subtitle2 = ref}}
                                dangerouslySetInnerHTML={{ __html: section41.bottom.subtitle }}
                              />
                            </div>

                          </div>
                          <div className="item right">
                            <div
                              className="p_main-page__advantages__main__title"
                              ref={ref => {this.advantagesRefs41.mainTitle = ref}}
                              dangerouslySetInnerHTML={{ __html: section41.title }}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </Slide>
                  <Slide>
                    <section
                      className="p_main-page__advantages__main"
                      onMouseEnter={() => changeCursorColor('black')}
                      onMouseLeave={() => changeCursorColor('white')}
                    >
                      <div className="container">
                        <img ref={ref => this.advantagesRefs42.divider = ref} src={process.env.PUBLIC_URL + 'divider42.svg'} className="advantages-divider" />
                        <div className="flex">
                          <div className="item">

                            <div className="p_main-page__advantages__main__stacked-item">
                              <div
                                className="p_main-page__advantages__main__stacked-item__title"
                                ref={ref => {this.advantagesRefs42.title1 = ref}}
                                dangerouslySetInnerHTML={{ __html: section42.top.title }}
                              />
                              <div
                                className="p_main-page__advantages__main__stacked-item__description"
                                ref={ref => {this.advantagesRefs42.subtitle1 = ref}}
                                dangerouslySetInnerHTML={{ __html: section42.top.subtitle }}
                              />
                            </div>

                            <div className="p_main-page__advantages__main__stacked-item">
                              <div
                                className="p_main-page__advantages__main__stacked-item__title"
                                ref={ref => {this.advantagesRefs42.title2 = ref}}
                                dangerouslySetInnerHTML={{ __html: section42.bottom.title }}
                              />
                              <div
                                className="p_main-page__advantages__main__stacked-item__description"
                                ref={ref => {this.advantagesRefs42.subtitle2 = ref}}
                                dangerouslySetInnerHTML={{ __html: section42.bottom.subtitle }}
                              />
                            </div>

                          </div>
                          <div className="item right">
                            <div
                              className="p_main-page__advantages__main__title"
                              ref={ref => {this.advantagesRefs42.mainTitle = ref}}
                              dangerouslySetInnerHTML={{ __html: section42.title }}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </Slide>
                  <Slide>
                    <section
                      className="p_main-page__advantages__main"
                      onMouseEnter={() => changeCursorColor('black')}
                      onMouseLeave={() => changeCursorColor('white')}
                    >
                      <div className="container">
                        <img ref={ref => this.advantagesRefs43.divider = ref} src={process.env.PUBLIC_URL + 'divider43.svg'} className="advantages-divider" />
                        <div className="flex">
                          <div className="item">

                            <div className="p_main-page__advantages__main__stacked-item">
                              <div
                                className="p_main-page__advantages__main__stacked-item__title"
                                ref={ref => {this.advantagesRefs43.title1 = ref}}
                                dangerouslySetInnerHTML={{ __html: section43.top.title }}
                              />
                              <div
                                className="p_main-page__advantages__main__stacked-item__description"
                                ref={ref => {this.advantagesRefs43.subtitle1 = ref}}
                                dangerouslySetInnerHTML={{ __html: section43.top.subtitle }}
                              />
                            </div>

                            <div className="p_main-page__advantages__main__stacked-item">
                              <div
                                className="p_main-page__advantages__main__stacked-item__title"
                                ref={ref => {this.advantagesRefs43.title2 = ref}}
                                dangerouslySetInnerHTML={{ __html: section43.bottom.title }}
                              />
                              <div
                                className="p_main-page__advantages__main__stacked-item__description"
                                ref={ref => {this.advantagesRefs43.subtitle2 = ref}}
                                dangerouslySetInnerHTML={{ __html: section43.bottom.subtitle }}
                              />
                            </div>

                          </div>
                          <div className="item right">
                            <div
                              className="p_main-page__advantages__main__title"
                              ref={ref => {this.advantagesRefs43.mainTitle = ref}}
                              dangerouslySetInnerHTML={{ __html: section43.title }}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </Slide>

                  <Slide>
                    <SectionFive
                      activeSection={this.state.activeSection}
                      changeCursorType={changeCursorType}
                      changeCursorColor={changeCursorColor}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      onMouseMove={onMouseMove}
                    />
                  </Slide>
                  <Slide>
                    <SectionSix activeSection={this.state.activeSection} />
                  </Slide>
                  <Slide>
                    <SectionSeven activeSection={this.state.activeSection} />
                  </Slide>
                  {/* <Slide>
                    <SectionEight activeSection={this.state.activeSection} />
                  </Slide> */}
                  <Slide>
                    <SectionNine activeSection={this.state.activeSection} />
                  </Slide>
                  {/* <Slide>
                    <SectionTen activeSection={this.state.activeSection} />
                  </Slide> */}
                  <Slide>
                    <SectionEleven
                      activeSection={this.state.activeSection}
                      changeCursorType={changeCursorType}
                      changeCursorColor={changeCursorColor}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      onMouseMove={onMouseMove}
                    />
                  </Slide>
                  <Slide>
                    <SectionTwelve
                      activeSection={this.state.activeSection}
                      changeCursorType={changeCursorType}
                      changeCursorColor={changeCursorColor}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      onMouseMove={onMouseMove}
                    />
                  </Slide>
                  <Slide>
                    <Footer />
                  </Slide>
                </FullPage>
              </div>
            )
          }
        </CursorContext.Consumer>
      )
  }
}

export default withLang({
  en: {
    section41: {
      title: `Total freedom for the traders`,
      top: {
        title: `Trade where ever you want -  Earn with Bill Trade`,
        subtitle: `You can use any programs,
        signals, terminals to signal on our platform`
      },
      bottom: {
        title: `No trading barriers `,
        subtitle: `Trade using any type of order, 
        leverage and asset`
      },
    },
    section42: {
      title: `Analyst`,
      top: {
        title: `Quality copying`,
        subtitle: `Analyst - Creates his own copying strategy and shares it with the other users of the platform.`
      },
      bottom: {
        title: `The best results`,
        subtitle: `By analyzing the statistic of the trader, an analyst can enhance his copying results, as well as dynamically change the allocated percentage and the numbers of traders in his portfolio`
      },
    },
    section43: {
      title: `Multiplier`,
      top: {
        title: `Risk management`,
        subtitle: `The ability to adjust risks (Profit and loss) relative to the trader. You can make a conservative trader aggressive and vice versa.`
      },
      bottom: {
        title: `Good diversification`,
        subtitle: `The ability to level the average profitability of traders and combine them into one portfolio`
      },
    }
  },
  ru: {
    section41: {
      title: `Полная свобода для трейдеров`,
      top: {
        title: `Торгуйте, где хотите - зарабатывайте с Bill Trade`,
        subtitle: `Вы можете использовать любые программы, сигналы, терминалы чтобы транслировать на нашей платформе`
      },
      bottom: {
        title: `Никаких торговых ограничений`,
        subtitle: `Торгуйте любым типом ордера, плечем и активом`
      },
    },
    section42: {
      title: `Аналитик`,
      top: {
        title: `Качественное копирование`,
        subtitle: `Аналитик - Создаёт свои стратегии копирования и транслирует их для дрыгих клиентов`
      },
      bottom: {
        title: `Лучшие результаты`,
        subtitle: `Анализируя статистику трейдера, аналитик может улучшить результат копирования, а так же динамически изменять процент и количество трейдеров в своем портфеле`
      },
    },
    section43: {
      title: `Множитель`,
      top: {
        title: `Эффективный риск менеджмент `,
        subtitle: `Возможность регулировать риски(Прибыли и убытки) относительно трейдера. Вы можете из консервативного трейдера сделать - агрессивного и наоборот.`
      },
      bottom: {
        title: `Хорошая диверсификация`,
        subtitle: `Возможность уровнять среднюю доходность трейдеров и обьеденить их в один портфель`
      },
    }
  },
})(MainPage);