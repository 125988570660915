import React, { PureComponent } from 'react';
import Button from '../../components/Button';

import firebase from 'firebase';
import { Determinator, withLang } from "react-multi-language";
import { FirebaseDatabaseMutation, FirebaseDatabaseProvider } from '@react-firebase/database';

class SectionTwelveNoLang extends PureComponent {
  state = {
    email: '',
    message: '',
    applied: false
  };

  componentDidUpdate() {
    if (this.props.activeSection === 12) {
      this.onInit();
    }
  }

  onInit = () => {

  }

  onSubmit = async () => {
    const { runMutation } = this.props;

    const { key } = await runMutation({
      email: this.state.email,
      message: this.state.message
    });
    this.setState({
      applied: true
    });
  }
  
  render() {
    const {
      title,
      description,
      emailPlaceholder,
      messagePlaceholder,
      button,
      allRightsReserved,
    } = this.props.langProps;

    const form = (
      <form action="">
        <input onChange={(e) => this.setState({ email: e.target.value })} name="email" type="email" type="text" placeholder={emailPlaceholder} />
        <textarea onChange={(e) => this.setState({ message: e.target.value })}  placeholder={messagePlaceholder} name="text" id="text" cols="30" rows="10"></textarea>
        <Button onClick={this.onSubmit} bodyStyle={{ width: '100%' }} textStyle={{ margin: 'auto' }}>{button}</Button>
      </form>
    );

    const applied = (
      <form action="">
        <input onChange={(e) => this.setState({ email: e.target.value })} name="email" type="email" type="text" placeholder={emailPlaceholder} />
        <textarea onChange={(e) => this.setState({ message: e.target.value })}  placeholder={messagePlaceholder} name="text" id="text" cols="30" rows="10"></textarea>
        <div className="p_main-page__footer__description">
          Message sent
        </div>
      </form>
    );

    return (
      <footer className="p_main-page__footer flex">
        <img className="p_main-page__footer__bg" src={process.env.PUBLIC_URL + '/bt-footer.svg'} />
        <div className="container flex item">
        <div className="item">
            {this.state.applied ? applied : form}
          </div>
          <div className="item">
            <div className="p_main-page__footer__title">
              {title}
            </div>
            <div className="p_main-page__footer__description">
              {description}
            </div>
          </div>
        </div>

        <div className="p_main-page__footer__socials flex item">
          <a href="https://www.facebook.com/BillTrade.io/" target="_blank">
            <img
              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
              onMouseMove={this.props.onMouseMove}
              className="icon item"
              src={process.env.PUBLIC_URL + '/facebook.svg'}
              alt=""
            />
          </a>
          <a href="https://t.me/BillTrade" target="_blank">
            <img
              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
              onMouseMove={this.props.onMouseMove}
              className="icon item"
              src={process.env.PUBLIC_URL + '/telegram.svg'}
              alt=""
            />
          </a>
          <a href="https://www.instagram.com/billtrade_io/" target="_blank">
            <img
              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
              onMouseMove={this.props.onMouseMove}
              className="icon item"
              src={process.env.PUBLIC_URL + '/instagram.svg'}
              alt=""
            />
          </a>
          <a href="https://www.linkedin.com/company/bill-trade/" target="_blank">
            <img
              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
              onMouseMove={this.props.onMouseMove}
              className="icon item"
              src={process.env.PUBLIC_URL + '/linkeding.svg'}
              alt=""
            />
          </a>
          <a href="https://medium.com/@billtrade" target="_blank">
            <img
              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
              onMouseMove={this.props.onMouseMove}
              className="icon item"
              src={process.env.PUBLIC_URL + '/medium.svg'}
              alt=""
            />
          </a>
        </div>
      </footer>
    )
  }
}

const SectionTwelveWithLang = withLang({
  en: {
    title: `Feel free to contact us`,
    description: `We are glad to help you 24 hours a day, 7 days a week. Just write us a letter and we’ll answer you as soon as possible!`,
    emailPlaceholder: `Enter your E-mail`,
    messagePlaceholder: `Write your question here`,
    button: `Send`,
    copyTrading: `Copy trading`,
    allRightsReserved: `Bill Trade. 2019 all rights reserved`
  },
  ru: {
    title: `Связаться с нами`,
    description: 'Наша клиентская поддержка доступна 24/7. Для оперативного решения вопросов просто создайте заявку в личном кабинете.',
    emailPlaceholder: `Введите ваш E-mail`,
    messagePlaceholder: `Задайте нам вопрос`,
    button: `Отправить`,
    copyTrading: `Копирование сделок`,
    allRightsReserved: `Bill Trade. 2019 все права защищены`
  }
})(SectionTwelveNoLang);

const SectionTwelve = () => (
  <FirebaseDatabaseProvider firebase={firebase} {...window.firebaseConfig}>
    <FirebaseDatabaseMutation type="push" path="/messages">
      {(props) => <SectionTwelveWithLang {...props} />}
    </FirebaseDatabaseMutation>
  </FirebaseDatabaseProvider>
);

export default SectionTwelve;
