import React, { memo } from 'react'

import './style.scss';

function VerticalDivider({
  children,
  color = '#D8D8D8',
  dividerStyle =  {},
  style = {}
}) {
  return (
    <div
      className="c_vertical-divider"
      style={style}
    >
      <div className="c_vertical-divider__container">
        <div
          className="c_vertical-divider__container__text"
          style={{ color }}
        >{children}</div>
        <div
          className="c_vertical-divider__container__dot"
          style={{ background: color }}
        ></div>
      </div>
      <div
        className="c_vertical-divider__line"
        style={{ background: color, ...dividerStyle }}
      ></div>
    </div>
  )
}

export default memo(VerticalDivider)
