import React, { PureComponent } from 'react'
import VizSensor from 'react-visibility-sensor';
import { TweenMax, Bounce, Elastic } from 'gsap';
import VerticalDivider from '../../components/VerticalDivider/VerticalDivider';
import { Determinator, withLang } from "react-multi-language";
import { useSpring, animated } from 'react-spring';

const Container = ({
  children,
  refc
}) => {
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
  const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`
  const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`
  const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`
  const trans5 = (x, y) => `linear-gradient(90deg, #fff ${(x - 30) / 40}%, rgb(104, 106, 114) ${(x - 10) / 20}%, #393A3E ${(x + 45) / 20}%)`
  
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 3, tension: 200, friction: 15 } }))
  return (
    <animated.section
      ref={refc}
      className="p_main-page__how-it-works"
    >
      {children}
    </animated.section>
  );
}

export default class SectionThree extends PureComponent {
  howItWorksRefs = {
    left1: null,
    left2: null,
    left3: null,
    left4: null,
    left5: null,
    right1: null,
    right2: null,
    right3: null,
    right4: null,
    right5: null,
    divider: null,
    container: null,
    right: null,
    left: null
  }

  componentDidUpdate() {
    if (this.props.activeSection === 3) {
      this.onInit();
    }
  }

  onInit = () => {
    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.divider,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          ease: 'Power4.easeOut',
          opacity: 1
        }
      );
    }, 100);

    TweenMax.to(
      this.howItWorksRefs.title,
      .7,
      {
        opacity: 1,
        transform: 'translateX(0px) translateY(0px)',
        ease: 'Power4.easeOut'
      }
    );
    TweenMax.to(
      this.howItWorksRefs.right,
      .7,
      {
        transform: 'translateX(0vw)'
      }
    );
    TweenMax.to(
      this.howItWorksRefs.container,
      .7,
      {
        backgroundImage: 'linear-gradient(90deg, #fff -40%, rgb(104, 106, 114) -40%, #393A3E 0%)'
      }
    );
    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.left1,
        .7,
        {
          left: '100%',
          transform: 'translateY(0vh)',
          opacity: 1
        }
      );
    }, 250);
    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.left2,
        .7,
        {
          left: '100%',
          transform: 'translateY(0vh)',
          opacity: 1
        }
      );
    }, 200);
    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.left3,
        .7,
        {
          left: '100%',
          transform: 'translateY(0vh)',
          opacity: 1
        }
      );
    }, 150);
    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.left4,
        .7,
        {
          left: '100%',
          transform: 'translateY(0vh)',
          opacity: 1
        }
      );
    }, 100);
    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.left5,
        .7,
        {
          left: '100%',
          transform: 'translateY(0vh)',
          opacity: 1
        }
      );
    }, 50);
    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.right1,
          .7,
          {
            transform: 'translateX(0px) translateY(0px) scale(1)',
            ease: 'Power4.easeOut',
            opacity: 1
          }
      );
    }, 200);

    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.right2,
          .7,
          {
            transform: 'translateX(0px) translateY(0px) scale(1)',
            ease: 'Power4.easeOut',
            opacity: 1
          }
      );
    }, 400);

    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.right3,
          .7,
          {
            transform: 'translateX(0px) translateY(0px) scale(1)',
            ease: 'Power4.easeOut',
            opacity: 1
          }
      );
    }, 600);
    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.right4,
          .7,
          {
            transform: 'translateX(0px) translateY(0px) scale(1)',
            ease: 'Power4.easeOut',
            opacity: 1
          }
      );
    }, 800);
    setTimeout(() => {
      TweenMax.to(
        this.howItWorksRefs.right5,
          .7,
          {
            transform: 'translateX(0px) translateY(0px) scale(1)',
            ease: 'Power4.easeOut',
            opacity: 1
          }
      );
    }, 1000);
  }
  
  renderDivider = () => {
    return (
      <svg ref={ref => {this.howItWorksRefs.divider = ref}} className="divider" xmlns="http://www.w3.org/2000/svg" width="16" height="500" viewBox="0 0 16 500">
        <g id="Group_2704" transform="translate(-741 -1562.5)">
          <text id="_5_easy_steps_to_work_with_bill_trade" transform="translate(748 1626) rotate(90)" fill="#9b9b9d" fontSize="12" fontFamily="Helvetica" letterSpacing="0.35em"><tspan x="0" y="0">
            <Determinator>
                {{
                  en: `5 easy steps to work with bill trade`,
                  ru: `5 простых шагов для работы с Bill Trade`,
                  ar: ``,
                  fr: ``
                }}
              </Determinator>
          </tspan></text>
          <line id="Line_466" y2="500" transform="translate(741.5 1562.5)" fill="none" stroke="#9b9b9d" strokeWidth="1"/>
        </g>
      </svg>
    );
  }
  
  render() {
    return (

      <Container refc={ref => {this.howItWorksRefs.container = ref}}>
        <img src="/benefits-bt.svg" className="p_main-page__how-it-works__bt" />
        {/* <div ref={ref => {this.howItWorksRefs.wave = ref}} className="wave"></div> */}
        <div className="wrapper">
            {this.renderDivider()}
            <div className="container flex">

              <div className="p_main-page__how-it-works__title-placeholder item"></div>
              <div
                className="p_main-page__how-it-works__title item"
                ref={ref => {this.howItWorksRefs.title = ref}}
              >
                <div>
                  <Determinator>
                    {{
                      en: `How`,
                      ru: `Как`,
                      ar: ``,
                      fr: ``
                    }}
                  </Determinator>
                </div>
                <div>
                  <Determinator>
                    {{
                      en: `does it work?`,
                      ru: `это работает?`,
                      ar: ``,
                      fr: ``
                    }}
                  </Determinator>
                </div>
              </div>

            </div>

            <div className="container flex cels">

              <div className="item" ref={ref => {this.howItWorksRefs.left = ref}}>
                <div
                  className="p_main-page__how-it-works__cell"
                >
                  <div
                    className="p_main-page__how-it-works__cell__background"
                  ></div>
                  <div className="p_main-page__how-it-works__cell__lg" ref={ref => {this.howItWorksRefs.left1 = ref}}>
                    <Determinator>
                      {{
                        en: `Sign up`,
                        ru: `Зарегистрируйтесь`,
                        ar: ``,
                        fr: ``
                      }}
                    </Determinator>
                  </div>
                </div>
                <div
                  className="p_main-page__how-it-works__cell"
                >
                  <div
                    className="p_main-page__how-it-works__cell__background"
                  ></div>
                  <div className="p_main-page__how-it-works__cell__lg" ref={ref => {this.howItWorksRefs.left2 = ref}}>
                    <Determinator>
                      {{
                        en: `Add API keys`,
                        ru: `Добавьте API ключи`,
                        ar: ``,
                        fr: ``
                      }}
                    </Determinator>
                  </div>
                </div>
                <div
                  className="p_main-page__how-it-works__cell"
                >
                  <div
                    className="p_main-page__how-it-works__cell__background"
                  ></div>
                  <div className="p_main-page__how-it-works__cell__lg" ref={ref => {this.howItWorksRefs.left3 = ref}}>
                    <Determinator>
                      {{
                        en: `Watch Ranks`,
                        ru: `Смотрите рейтинги`,
                        ar: ``,
                        fr: ``
                      }}
                    </Determinator>
                  </div>
                </div>
                <div
                  className="p_main-page__how-it-works__cell"
                >
                  <div
                    className="p_main-page__how-it-works__cell__background"
                  ></div>
                  <div className="p_main-page__how-it-works__cell__lg" ref={ref => {this.howItWorksRefs.left4 = ref}}>
                    <Determinator>
                      {{
                        en: `Compare and Follow`,
                        ru: `Оцените лучших`,
                        ar: ``,
                        fr: ``
                      }}
                    </Determinator>
                  </div>
                </div>
                <div
                  className="p_main-page__how-it-works__cell"
                >
                  <div
                    className="p_main-page__how-it-works__cell__background"
                  ></div>
                  <div className="p_main-page__how-it-works__cell__lg" ref={ref => {this.howItWorksRefs.left5 = ref}}>
                  <Determinator>
                    {{
                      en: `Copy Deals`,
                      ru: `Копируйте сделки`,
                      ar: ``,
                      fr: ``
                    }}
                  </Determinator>
                  </div>
                </div>
              </div>

              <div
                className="item"
                ref={ref => {this.howItWorksRefs.right = ref}}
              >
                <div
                  className="p_main-page__how-it-works__cell"
                >
                  <div
                    className="p_main-page__how-it-works__cell__sm"
                    ref={ref => {this.howItWorksRefs.right1 = ref}}
                  >
                    <Determinator>
                      {{
                        en: `Join Bill Trade`,
                        ru: `присоединяйтесь к Bill Trade`,
                        ar: ``,
                        fr: ``
                      }}
                    </Determinator>
                    <a href="https://dash.billtrade.io"><img src="/how-does-it-work-arrow.svg" /></a>
                  </div>
                </div>
                  <div
                    className="p_main-page__how-it-works__cell"
                  >
                  <div
                    className="p_main-page__how-it-works__cell__sm"
                    ref={ref => {this.howItWorksRefs.right2 = ref}}
                  >
                    <Determinator>
                      {{
                        en: `Add the API key from the exchange that stores your assets`,
                        ru: `Добавьте API-ключ от биржи, в которой хранятся ваши активы`,
                        ar: ``,
                        fr: ``
                      }}
                    </Determinator>
                  </div>
                </div>
                  <div
                    className="p_main-page__how-it-works__cell"
                  >
                  <div
                    className="p_main-page__how-it-works__cell__sm"
                    ref={ref => {this.howItWorksRefs.right3 = ref}}
                  >
                    <Determinator>
                      {{
                        en: `Bill Trade compiles trading statistics and ranks our top traders`,
                        ru: `BillTrade собирает статистику торговли и оценивает наших лучших трейдеров`,
                        ar: ``,
                        fr: ``
                      }}
                    </Determinator>
                  </div>
                </div>
                  <div
                    className="p_main-page__how-it-works__cell"
                  >
                  <div
                    className="p_main-page__how-it-works__cell__sm"
                    ref={ref => {this.howItWorksRefs.right4 = ref}}
                  >
                    <Determinator>
                      {{
                        en: `Investors choose leading traders to follow and get profit`,
                        ru: `Инвесторы выбирают лидеров, чтобы следовать и получать прибыль`,
                        ar: ``,
                        fr: ``
                      }}
                    </Determinator>
                  </div>
                </div>
                  <div
                    className="p_main-page__how-it-works__cell"
                  >
                  <div
                    className="p_main-page__how-it-works__cell__sm"
                    ref={ref => {this.howItWorksRefs.right5 = ref}}
                  >
                    <Determinator>
                      {{
                        en: `Experienced traders make successful trades which others can copy`,
                        ru: `Профессионалы совершают успешные сделки, которые могут быть скопированны другими`,
                        ar: ``,
                        fr: ``
                      }}
                    </Determinator>
                  </div>
                </div>
              </div>

            </div>
        </div>
      </Container>
    )
  }
}
