import React, { PureComponent, useState } from 'react'
import { Determinator, withLang } from "react-multi-language";
import { TweenMax, Bounce } from 'gsap';

const PathZero = ({ filter }) => <path id="1" d="M-18.27-15.21Q-32.04,2.7-56.7,2.7T-95.13-15.21Q-108.9-33.12-108.9-63t13.77-47.79Q-81.36-128.7-56.7-128.7t38.43,17.91Q-4.5-92.88-4.5-63T-18.27-15.21ZM-76.68-63q0,34.38,19.98,34.38T-36.72-63q0-34.38-19.98-34.38T-76.68-63Z" transform="translate(90.9 126)" fill={`url(#${filter})`}/>;
const PathOne = ({ filter }) => <path id="1" d="M-39.42-126H-11.7V0H-43.92V-92.16l-21.96,5.58-7.74-27.18Z" transform="translate(78.62 126)" fill={`url(#${filter})`}/>;
const PathTwo = ({ filter }) => <path id="2" d="M-93.24,0V-23.4l41.76-44.1A104.845,104.845,0,0,0-42.3-78.57a14.832,14.832,0,0,0,2.88-8.37,10.055,10.055,0,0,0-2.97-7.56q-2.97-2.88-8.19-2.88-11.16,0-16.92,13.14L-95.22-99.72q6.12-14.22,18-21.6a48.816,48.816,0,0,1,26.28-7.38q18,0,30.87,10.71T-7.2-88.74a41.13,41.13,0,0,1-5.13,20.25A95.464,95.464,0,0,1-27.36-48.96l-17.1,17.82H-5.04V0Z" transform="translate(95.22 128)" fill={`url(#${filter})`}/>;
const PathThree = ({ filter }) => <path id="3" d="M-31.5-74.7A39.374,39.374,0,0,1-12.06-61.74q7.38,9.18,7.38,22.14Q-4.68-19.26-18-8.28T-50.94,2.7A53.506,53.506,0,0,1-78.75-4.5,40.886,40.886,0,0,1-96.3-25.74l28.08-16.02q3.96,13.14,17.28,13.14,6.84,0,10.53-3.24a10.927,10.927,0,0,0,3.69-8.64,10.657,10.657,0,0,0-3.69-8.55Q-44.1-52.2-50.94-52.2h-6.84L-70.2-70.56l20.16-24.66h-42.3V-126h81.9v25.38Z" transform="translate(98.3 125)" fill={`url(#${filter})`}/>;
const PathFour = ({ filter }) => <path id="4" d="M-1.44-48.6v29.34h-12.6V0H-46.08V-19.26h-55.26V-48.6L-63.72-126h35.64L-66.06-48.6h19.98V-71.28h32.04V-48.6Z" transform="translate(80.34 127)" fill={`url(#${filter})`}/>;
const PathFive = ({ filter }) => <path id="5" d="M-46.8-81.72q18.54,0,30.87,10.8T-3.6-40.32q0,20.52-13.32,31.77T-50.04,2.7q-16.56,0-28.8-7.11T-96.66-25.74l28.08-16.02q3.96,13.14,18.54,13.14,6.84,0,10.53-3.15a10.657,10.657,0,0,0,3.69-8.55q0-5.58-3.69-8.64t-9.99-3.06H-89.82L-84.6-126h74.16v30.78H-54.9l-.9,13.5Z" transform="translate(96.66 125)" fill={`url(#${filter})`}/>;
const PathSix = ({ filter }) => <path id="6" d="M-43.02-85.86q18.36,2.34,29.34,14.31T-2.7-41.76q0,19.62-13.14,32.04T-50.4,2.7q-21.42,0-34.56-12.42T-98.1-41.76a44.453,44.453,0,0,1,7.92-26.1L-52.92-126h36.54ZM-50.4-26.82a15.95,15.95,0,0,0,11.07-3.96q4.41-3.96,4.41-10.98,0-7.2-4.32-11.16A15.87,15.87,0,0,0-50.4-56.88a15.87,15.87,0,0,0-11.16,3.96q-4.32,3.96-4.32,11.16t4.32,11.07A16.093,16.093,0,0,0-50.4-26.82Z" transform="translate(90.1 125)" fill={`url(#${filter})`}/>;
const PathSeven = ({ filter }) => <path id="7" d="M-88.02-126H-2.7v26.64L-42.12,0H-76.86l37.44-94.86h-48.6Z" transform="translate(88.02 126)" fill={`url(#${filter})`}/>;
const PathEight = ({ filter }) => <path id="8" d="M-20.88-65.88Q-4.14-55.44-4.14-35.46q0,17.64-12.24,27.81T-51.12,2.52q-22.68,0-34.92-10.17T-98.28-35.46q0-19.98,16.74-30.42-12.42-9.18-12.42-25.2,0-17.64,12.06-27.54t30.78-9.9q18.54,0,30.6,9.99T-8.46-91.08Q-8.46-75.06-20.88-65.88ZM-51.12-99.36q-5.76,0-9.09,2.97a9.986,9.986,0,0,0-3.33,7.83,9.921,9.921,0,0,0,3.33,7.92q3.33,2.88,9.09,2.88a12.967,12.967,0,0,0,8.82-2.97,9.865,9.865,0,0,0,3.42-7.83,9.865,9.865,0,0,0-3.42-7.83A12.967,12.967,0,0,0-51.12-99.36Zm0,72.36q6.66,0,10.8-3.51a11.752,11.752,0,0,0,4.14-9.45,11.955,11.955,0,0,0-4.14-9.63q-4.14-3.51-10.8-3.51-6.84,0-10.89,3.51a12.077,12.077,0,0,0-4.05,9.63,11.869,11.869,0,0,0,4.05,9.45Q-57.96-27-51.12-27Z" transform="translate(98.28 128)" fill={`url(#${filter})`}/>;
const PathNine = ({ filter }) => <path id="9" d="M-2.7-84.24a44.453,44.453,0,0,1-7.92,26.1L-47.88,0H-84.42l26.64-40.14q-18.36-2.34-29.34-14.31T-98.1-84.24q0-19.62,13.14-32.04T-50.4-128.7q21.42,0,34.56,12.42T-2.7-84.24Zm-63.18,0q0,7.2,4.32,11.16A15.87,15.87,0,0,0-50.4-69.12a15.87,15.87,0,0,0,11.16-3.96q4.32-3.96,4.32-11.16t-4.32-11.07A16.093,16.093,0,0,0-50.4-99.18a15.95,15.95,0,0,0-11.07,3.96Q-65.88-91.26-65.88-84.24Z" transform="translate(98.1 126)" fill={`url(#${filter})`}/>;

class Counter extends React.PureComponent {
  interval = null;
  number = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      init: false,
      elapsedTime: props.from || 0,
      to: props.to
    };
  }

  componentDidMount() {
    this.initAnimation();
    this.startCounting();
  }

  initAnimation = () => {
    setTimeout(() => {
      this.setState({
        init: true
      });
    }, this.props.offset);
  }

  startCounting = () => {
    this.initAnimation();
    if (this.props.offset) {
      setTimeout(() => {
        this.interval = setInterval(this.countUp, 100);
      }, this.props.offset);
    } else {
      this.interval = setInterval(this.countUp, 100);
    }
  }

  countUp = () => {
    if (this.state.elapsedTime < this.props.to) {
      this.setState(({ elapsedTime }) => ({ elapsedTime: elapsedTime + 1 }));
    } else {
      clearInterval(this.interval);
    }
  }

  render() {
    const {
      filter
    } = this.props;

    const numbers = [
      <PathZero filter={filter} />,
      <PathOne filter={filter} />,
      <PathTwo filter={filter} />,
      <PathThree filter={filter} />,
      <PathFour filter={filter} />,
      <PathFive filter={filter} />,
      <PathSix filter={filter} />,
      <PathSeven filter={filter} />,
      <PathEight filter={filter} />,
      <PathNine filter={filter} />,
    ];

    return (
      <svg
        className={`number-vector ${this.state.init ? 'active' : ''}`}
        width="114"
        height="131"
        viewBox="0 0 81.92 146"
      >
        <defs>
          <linearGradient id="linear-gradient-gold-1" x1="2.781" y1="4.381" x2="0.28" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff4500"/>
            <stop offset="1" stopColor="#7fffd4"/>
          </linearGradient>
          <linearGradient id="linear-gradient-gold-2" x1="2.652" y1="3.27" x2="0.312" y2="-1.047" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff4500"/>
            <stop offset="1" stopColor="#7fffd4"/>
          </linearGradient>
          <linearGradient id="linear-gradient-gold-3" x1="2.748" y1="2.159" x2="0.28" y2="-2.205" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff4500"/>
            <stop offset="1" stopColor="#7fffd4"/>
          </linearGradient>
          <linearGradient id="linear-gradient-gold-4" x1="2.748" y1="1" x2="0.312" y2="-3.403" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ff4500"/>
            <stop offset="1" stopColor="#7fffd4"/>
          </linearGradient>
        </defs>
        <g>
          {numbers[this.state.elapsedTime]}
        </g>
      </svg>
    );
  }
}

class SectionSevenNoLang extends PureComponent {
  percent = null;
  titleRef = null;
  numberRef = null;
  divider1 = null;
  divider2 = null;
  divider3 = null;
  divider4 = null;
  sponsors = null;
  state = {
    mounted: false,
  };

  componentDidUpdate() {
    if (this.props.activeSection === 9 && !this.state.mounted) {
      this.setState({
        mounted: true,
      }, this.onInit);
    }
  }

  onInit = () => {
    setTimeout(() => {
      TweenMax.to(
        this.divider1,
        .3,
        {
          transform: 'translate(0px, 0px)',
          opacity: 1
        }
      );
    }, 50);
    setTimeout(() => {
      TweenMax.to(
        this.divider2,
        .3,
        {
          transform: 'translate(0px, 0px)',
          opacity: 1
        }
      );
    }, 200);
    setTimeout(() => {
      TweenMax.to(
        this.divider3,
        .3,
        {
          transform: 'translate(0px, 0px)',
          opacity: 1
        }
      );
    }, 300);
    setTimeout(() => {
      TweenMax.to(
        this.divider4,
        .3,
        {
          transform: 'translate(0px, 0px)',
          opacity: 1
        }
      );
    }, 400);
    setTimeout(() => {
      TweenMax.to(
        this.percent,
        .5,
        {
          transform: 'scale(1) rotate(0deg) translateX(0px) translateY(0px)',
          opacity: 1
        }
      );
    }, 700);

    TweenMax.to(
      this.titleRef,
      .7,
      {
        transform: 'translateX(0px) translateY(0px)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );

    TweenMax.to(
      this.numberRef,
      .7,
      {
        transform: 'translateX(0px) translateY(0px)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );

    TweenMax.to(
      this.sponsors,
      1.5,
      {
        transform: 'translateX(0px) translateY(0px)',
        ease: 'Power4.easeOut',
        opacity: 1
      }
    );
  }

  render() {
    const {
      title1,
      title2,
      subtitle
    } = this.props.langProps;

    const data = [
      {
        name: 'coin',
        logo: '/coinfox.png'
      },
      {
        name: 'coingogo',
        logo: '/forbes.png',
      },
      {
        name: 'coingogo',
        logo: '/coingogo.png',
      },
    ]

    return (
      <section
        className="p_main-page__about-billtrade"
      >
        <div className="container flex">
          <div className="item left-column">
              <div
                ref={ref => {this.titleRef = ref}}
                className="p_main-page__about-billtrade__title"
              >
                <div>{title1}</div>
                <div>{title2}</div>
              </div>
              <div
                className="p_main-page__about-billtrade__description"
                ref={ref => {this.numberRef = ref}}
              >
                {subtitle}
              </div>
              {/* <div className="p_main-page__about-billtrade__sponsors" ref={ref => this.sponsors = ref}>
                {data.map(item => (<img style={{ width: 100 }} src={process.env.PUBLIC_URL + item.logo} />))}
              </div> */}
          </div>
          <div className="item p_main-page__about-billtrade__numbers">
            <div className="c_billtrade-counter flex">
              <div className="item">
                {this.state.mounted ? <Counter
                  offset={50}
                  to={1}
                  filter="linear-gradient-gold-1"
                /> : ''}
                <div ref={ref => {this.divider1 = ref}} className="divider">
                  <Determinator>
                    {{
                      en: 'Used by funds',
                      ru: 'Используется фондами'
                    }}
                  </Determinator>
                </div>
              </div>
              <div className="item">
                {this.state.mounted ? <Counter
                  offset={100}
                  from={1}
                  to={8}
                  filter="linear-gradient-gold-2"
                /> : ''}
                <div ref={ref => {this.divider2 = ref}}  className="divider">
                  <Determinator>
                    {{
                      en: 'Traders',
                      ru: 'Трейдеров'
                    }}
                  </Determinator>
                </div>
              </div>
              <div className="item flex">
                <div className="item">
                  {this.state.mounted ? <Counter
                    from={1}
                    offset={350}
                    to={3}
                    filter="linear-gradient-gold-3"
                  /> : ''}
                </div>
                <div className="item">
                  {this.state.mounted ? <Counter
                    from={1}
                    offset={300}
                    to={4}
                    filter="linear-gradient-gold-3"
                  /> : ''}
                </div>
                <div ref={ref => {this.divider3 = ref}}  className="divider">
                  <Determinator>
                    {{
                      en: 'Users',
                      ru: 'Пользователей'
                    }}
                  </Determinator>
                </div>
              </div>
              <div className="item">
                <div className="numbers-container">
                  <div className="item">
                    {this.state.mounted ? <Counter
                      offset={400}
                      from={1}
                      to={1}
                      filter="linear-gradient-gold-4"
                    />  : ''}
                  </div>
                  <div className="item">
                    {this.state.mounted ? <Counter
                      offset={500}
                      from={1}
                      to={6}
                      filter="linear-gradient-gold-4"
                    />  : ''}
                  </div>
                  <div className="item">
                    {this.state.mounted ? <Counter
                      offset={700}
                      from={1}
                      to={2}
                      filter="linear-gradient-gold-4"
                    />  : ''}
                  </div>
                  <div className="item">
                  <svg ref={ref => {this.percent = ref}} className="number-vector percent" width="134" height="128" viewBox="0 0 81.92 146">
                    <path id="Path_4196" d="M-108.72-64.08a32.19,32.19,0,0,1-23.58-9.45,31.1,31.1,0,0,1-9.54-22.95,30.8,30.8,0,0,1,9.54-22.86q9.54-9.36,23.58-9.36t23.4,9.36a31.1,31.1,0,0,1,9.36,22.86,31.161,31.161,0,0,1-9.45,23.04A31.83,31.83,0,0,1-108.72-64.08Zm-14.4,44.28,82.8-90,12.6,7.2L-110.7-12.78Zm14.22-67.14a8.824,8.824,0,0,0,6.75-2.79,9.524,9.524,0,0,0,2.61-6.75,9.212,9.212,0,0,0-2.61-6.66,8.971,8.971,0,0,0-6.75-2.7,8.754,8.754,0,0,0-6.57,2.7,9.212,9.212,0,0,0-2.61,6.66,9.441,9.441,0,0,0,2.61,6.84A8.754,8.754,0,0,0-108.9-86.94ZM-39.06,2.52q-14.22,0-23.67-9.36A30.949,30.949,0,0,1-72.18-29.7a31.161,31.161,0,0,1,9.45-23.04q9.45-9.36,23.67-9.36,14.04,0,23.4,9.45A31.4,31.4,0,0,1-6.3-29.7,30.949,30.949,0,0,1-15.75-6.84,31.83,31.83,0,0,1-39.06,2.52Zm-6.75-25.56a8.754,8.754,0,0,0,6.57,2.7,8.615,8.615,0,0,0,6.57-2.79,9.3,9.3,0,0,0,2.61-6.57,9.441,9.441,0,0,0-2.61-6.84,8.754,8.754,0,0,0-6.57-2.7,8.754,8.754,0,0,0-6.57,2.7,9.441,9.441,0,0,0-2.61,6.84A9.212,9.212,0,0,0-45.81-23.04Z" transform="translate(111.84 129)" fill="url(#linear-gradient-gold-4)"/>
                  </svg>
                  </div>
                  <div ref={ref => {this.divider4 = ref}}  className="divider">
                    <Determinator>
                      {{
                        en: 'Profit*',
                        ru: 'Прибыль*'
                      }}
                    </Determinator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const SectionSeven = withLang({
  en: {
    title1: `About`,
    title2: `Bill Trade`,
    subtitle: `in numbers `,
  },
  ru: {
    title1: `О`,
    title2: `Bill Trade`,
    subtitle: `в цифрах`,
  }
})(SectionSevenNoLang);

export default SectionSeven;