import React, { PureComponent } from 'react';
import { TweenMax } from 'gsap';
import { Determinator, withLang } from "react-multi-language";

class SectionFiveNoLang extends PureComponent {
  whyInvestorsRefs = {
    title: null,
    item1: null,
    item2: null,
    item3: null,
    item4: null,
    item5: null,
    item6: null,
    billtradeTitle1: null,
    billtradeTitle2: null,
    billtradeTitle3: null,
    billtradeTitle4: null,
  }

  componentDidUpdate() {
    if (this.props.activeSection === 7) {
      this.onInit();
    }
  }

  onInit = () => {
    TweenMax.to(
      this.whyInvestorsRefs.title,
      .7,
      {
        transform: 'translateX(0)',
        opacity: 1,
        textShadow: '0px 0px 0px rgb(189, 45, 160)',
        ease: 'Power4.easeOut'
      }
    );

    setTimeout(() => {
      TweenMax.to(
        this.whyInvestorsRefs.item1,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          ease: 'Power4.easeOut',
          opacity: 1
        }
      );
    }, 50);
    setTimeout(() => {
      TweenMax.to(
        this.whyInvestorsRefs.item3,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          ease: 'Power4.easeOut',
          opacity: 1
        }
      );
    }, 100);
    setTimeout(() => {
      TweenMax.to(
        this.whyInvestorsRefs.item5,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          ease: 'Power4.easeOut',
          opacity: 1
        }
      );
    }, 150);
    setTimeout(() => {
      TweenMax.to(
        this.whyInvestorsRefs.item2,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          ease: 'Power4.easeOut',
          opacity: 1
        }
      );
    }, 200);
    setTimeout(() => {
      TweenMax.to(
        this.whyInvestorsRefs.item4,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          ease: 'Power4.easeOut',
          opacity: 1
        }
      );
    }, 250);
    setTimeout(() => {
      TweenMax.to(
        this.whyInvestorsRefs.item6,
        .7,
        {
          transform: 'translateX(0px) translateY(0px) scale(1)',
          ease: 'Power4.easeOut',
          opacity: 1
        }
      );
    }, 300);
    TweenMax.to(
      this.whyInvestorsRefs.billtradeTitle1,
      .7,
      {
        left: '100%',
        borderLeftWidth: 0,
        ease: 'Power4.easeIn',
      }
    );
    TweenMax.to(
      this.whyInvestorsRefs.billtradeTitle2,
      .8,
      {
        left: '100%',
        borderLeftWidth: 0,
        ease: 'Power4.easeIn',
      }
    );
    TweenMax.to(
      this.whyInvestorsRefs.billtradeTitle3,
      .9,
      {
        left: '100%',
        borderLeftWidth: 0,
        ease: 'Power4.easeIn',
      }
    );
    TweenMax.to(
      this.whyInvestorsRefs.billtradeTitle4,
      1,
      {
        left: '100%',
        borderLeftWidth: 0,
        ease: 'Power4.easeIn',
      }
    );
  }

  render() {
    const {
      title1,
      title2,
      title3,
      item1,
      item2,
      item3,
      item4,
      item5,
      item6,
    } = this.props.langProps;
    return (
      <section className="p_main-page__why-investors-choose">
        <div
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/white-paper.svg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
            position: 'absolute',
            height: '100vh',
            width: '100%',
            right: 0,
            left: 0,
            top: '-40vh',
          }}
        >
        </div>
          <div className="container flex">
            <div className="item title-container left-column">
              <div ref={ref => {this.whyInvestorsRefs.title = ref}} className="p_main-page__why-investors-choose__title">
                <div className="p_main-page__why-investors-choose__bt-container">
                  {title1}
                  <div
                    className="p_main-page__why-investors-choose__bt-container__title"
                    ref={ref => {this.whyInvestorsRefs.billtradeTitle1 = ref}}
                  ></div>
                </div>
                <div className="p_main-page__why-investors-choose__bt-container">
                  {title2}
                  <div
                    className="p_main-page__why-investors-choose__bt-container__title"
                    ref={ref => {this.whyInvestorsRefs.billtradeTitle2 = ref}}
                  ></div>
                </div>
                <div className="p_main-page__why-investors-choose__bt-container">
                  {title3}
                  <div
                    className="p_main-page__why-investors-choose__bt-container__title"
                    ref={ref => {this.whyInvestorsRefs.billtradeTitle3 = ref}}
                  ></div>
                </div>

                <div className="p_main-page__why-investors-choose__bt-container">
                  Bill Trade?<br />
                  <div
                    className="p_main-page__why-investors-choose__bt-container__title"
                    ref={ref => {this.whyInvestorsRefs.billtradeTitle4 = ref}}
                  ></div>
                </div>

              </div>
            </div>
            <div className="item flex right-column">
              <div
                className="item"
              >
                <div 
                  className="p_main-page__why-investors-choose__tile"
                  ref={ref => {this.whyInvestorsRefs.item1 = ref}}
                >
                  <div className="p_main-page__why-investors-choose__tile__icon">
                    <svg width="68" height="67" viewBox="0 0 68 67">
                      <g transform="translate(-585.61 -1276.2)">
                        <path d="M443,949.582A3.418,3.418,0,0,1,439.582,953h-3.027a3.418,3.418,0,0,1-.391-6.814V945.4a3.418,3.418,0,1,1,6.836,0Zm-11.5,0A3.418,3.418,0,0,1,428.081,953h-8.367a3.418,3.418,0,1,1,0-6.836h8.367A3.418,3.418,0,0,1,431.5,949.582Zm-23.322,0A3.418,3.418,0,0,1,404.759,953h-5.341A3.418,3.418,0,0,1,396,949.582V944.33a3.418,3.418,0,1,1,6.836,0v1.834h1.923A3.418,3.418,0,0,1,408.177,949.582Zm31.4-12.48a3.418,3.418,0,0,1-3.418-3.418v-8.368a3.418,3.418,0,0,1,6.836,0v8.368A3.418,3.418,0,0,1,439.582,937.1Zm-40.164-4.059A3.418,3.418,0,0,1,396,929.625v-8.368a3.418,3.418,0,1,1,6.836,0v8.368A3.418,3.418,0,0,1,399.418,933.043Zm40.164-16.023a3.418,3.418,0,0,1-3.418-3.418v-.766H435.4a3.418,3.418,0,1,1,0-6.836h4.184A3.418,3.418,0,0,1,443,909.418V913.6A3.418,3.418,0,0,1,439.582,917.02Zm-40.164-1.068A3.418,3.418,0,0,1,396,912.534v-3.116A3.418,3.418,0,0,1,399.418,906H403.6a3.418,3.418,0,1,1,0,6.836h-.779A3.419,3.419,0,0,1,399.418,915.952Zm27.684-6.534a3.418,3.418,0,0,1-3.418,3.418h-8.368a3.418,3.418,0,1,1,0-6.836h8.368A3.418,3.418,0,0,1,427.1,909.418Z" transform="translate(210.61 370.2)" fill="#ff4500" fillRule="evenodd"/>
                        <path d="M422,969.582A3.418,3.418,0,0,1,418.582,973H378.418A3.418,3.418,0,0,1,375,969.582V929.418A3.418,3.418,0,0,1,378.418,926h40.164A3.418,3.418,0,0,1,422,929.418Zm-6.836-3.418V932.836H381.836v33.328Z" transform="translate(210.61 370.2)" fill="#fff" fillRule="evenodd"/>
                      </g>
                    </svg>
                  </div>
                  <div className="p_main-page__why-investors-choose__tile__description">{item1}</div>
                </div>
                <div
                  className="p_main-page__why-investors-choose__tile"
                  ref={ref => {this.whyInvestorsRefs.item2 = ref}}
                >
                  <div className="p_main-page__why-investors-choose__tile__icon">
                    <svg width="59" height="61.868" viewBox="0 0 59 61.868">
                      <g transform="translate(-1212.61 -1482.832)">
                        <rect width="49" height="35" rx="4" transform="translate(1217.61 1509.2)" fill="#fff"/>
                        <rect width="59" height="16" rx="4" transform="translate(1212.61 1498.2)" fill="#fff"/>
                        <rect width="49" height="8" rx="4" transform="translate(1217.609 1501.2)" fill="#3a3a3a"/>
                        <rect width="6" height="46" transform="translate(1238.61 1498.2)" fill="#ff4500" stroke="#ff4500" strokeWidth="1"/>
                        <path d="M1051.79,1120.98a7.679,7.679,0,0,0-14.04-1.35L1031.5,1131h12.98a7.678,7.678,0,0,0,7.31-10.02Z" transform="translate(210.61 370.2)" fill="none" stroke="#ff4500" strokeWidth="6"/>
                        <path d="M1010.96,1121.34a7.475,7.475,0,0,1,13.76-1.29l5.76,10.95h-12.37a7.474,7.474,0,0,1-7.15-9.66Z" transform="translate(210.61 370.2)" fill="none" stroke="#ff4500" strokeWidth="6"/>
                      </g>
                    </svg>
                  </div>
                  <div className="p_main-page__why-investors-choose__tile__description">{item2}</div>
                </div>
              </div>
              <div
                className="item"
              >
                <div
                  className="p_main-page__why-investors-choose__tile"
                  ref={ref => {this.whyInvestorsRefs.item3 = ref}}
                >
                  <div className="p_main-page__why-investors-choose__tile__icon">
                    <svg width="75" height="51.227" viewBox="0 0 75 51.227">
                      <g transform="translate(-892.61 -1283.2)">
                        <path d="M685.125,928.625a3.125,3.125,0,0,1,0-6.25h1.3a15.631,15.631,0,0,1,27.743-1.761,11.1,11.1,0,0,1,10.664,0,15.631,15.631,0,0,1,27.743,1.761h1.3a3.125,3.125,0,0,1,0,6.25,15.625,15.625,0,0,1-31.25,0c0-.527.026-1.049.077-1.563h-.077c0-.635-1.236-1.562-3.125-1.562s-3.125.927-3.125,1.562H716.3c.051.514.077,1.036.077,1.563a15.625,15.625,0,0,1-31.25,0ZM700.75,938a9.375,9.375,0,1,0-9.375-9.375A9.375,9.375,0,0,0,700.75,938Zm46.875-9.375a9.375,9.375,0,1,1-9.375-9.375A9.375,9.375,0,0,1,747.625,928.625Z" transform="translate(210.61 370.2)" fill="#fff" fillRule="evenodd"/>
                        <path d="M705,958h0a29.107,29.107,0,0,0,28.5,0h0" transform="translate(210.61 370.2)" fill="none" stroke="#ff4500" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"/>
                      </g>
                    </svg>
                  </div>
                  <div className="p_main-page__why-investors-choose__tile__description">{item3}</div>
                </div>
                <div
                  className="p_main-page__why-investors-choose__tile"
                  ref={ref => {this.whyInvestorsRefs.item4 = ref}}
                >
                  <div className="p_main-page__why-investors-choose__tile__icon">
                    <svg width="68.75" height="37.5" viewBox="0 0 68.75 37.5">
                      <g transform="translate(-896.735 -1493.95)">
                        <path d="M720,1134.35c-4.185-7.66-8.032-10.6-15.125-10.6a18.75,18.75,0,0,0,0,37.5c4.722,0,9.857-1.78,14.24-4.28a27.2,27.2,0,0,1-3.68-5.1,23.869,23.869,0,0,1-10.56,3.13,12.5,12.5,0,0,1,0-25c5.409,0,8,3.18,12.775,13.78.817,1.82,1.593,3.47,2.35,4.96Z" transform="translate(210.61 370.2)" fill="#ff4500" fillRule="evenodd"/>
                        <path d="M720,1148.74c4.6,9.09,8.471,12.51,16.125,12.51a18.75,18.75,0,0,0,0-37.5c-5.529,0-8.852,1.35-12.225,4.57-.2.19-.542.53-.94.94a36.1,36.1,0,0,1,3.463,5.41c.764-.79,1.494-1.55,1.788-1.83,2.22-2.11,4.005-2.84,7.914-2.84a12.5,12.5,0,0,1,0,25c-5.409,0-8-3.18-12.775-13.78-1.18-2.62-2.276-4.9-3.35-6.87Z" transform="translate(210.61 370.2)" fill="#fff" fillRule="evenodd"/>
                      </g>
                    </svg>
                  </div>
                  <div className="p_main-page__why-investors-choose__tile__description">{item4}</div>
                </div>
              </div>
              <div
                className="item"
              >
                <div
                  className="p_main-page__why-investors-choose__tile"
                  ref={ref => {this.whyInvestorsRefs.item5 = ref}}
                >
                  <div className="p_main-page__why-investors-choose__tile__icon">
                    <svg width="50" height="74" viewBox="0 0 50 74">
                      <g transform="translate(-1216.61 -1273.2)">
                        <path d="M1044,929l12,9v9h-12Z" transform="translate(210.61 370.2)" fill="#fff"/>
                        <path d="M1018,929l-12,9v9h12Z" transform="translate(210.61 370.2)" fill="#fff"/>
                        <path d="M1018,916a13,13,0,0,1,13-13h0a13,13,0,0,1,13,13v30a5,5,0,0,1-5,5h-16a5,5,0,0,1-5-5Z" transform="translate(210.61 370.2)" fill="#fff"/>
                        <path d="M1031.5,927a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,1031.5,927Z" transform="translate(210.61 370.2)" fill="#ff4500" fillRule="evenodd"/>
                        <rect width="6" height="19" transform="translate(1238.61 1307.2)" fill="#fff" stroke="#fff" strokeWidth="1"/>
                        <path d="M1031.5,977l-6.02-9.592a5,5,0,0,1,4.23-7.658h3.57a5,5,0,0,1,4.24,7.658Z" transform="translate(210.61 370.2)" fill="#ff4500"/>
                      </g>
                    </svg>
                  </div>
                  <div className="p_main-page__why-investors-choose__tile__description">{item5}</div>
                </div>
                <div
                  className="p_main-page__why-investors-choose__tile"
                  ref={ref => {this.whyInvestorsRefs.item6 = ref}}
                >
                  <div className="p_main-page__why-investors-choose__tile__icon">
                  <svg width="62.5" height="62.5" viewBox="0 0 62.5 62.5">
                    <g transform="translate(-588.61 -1481.2)">
                      <path d="M437.375,1117.25v31.25a6.251,6.251,0,0,1-6.25,6.25h-18.75v4.48l14.108,8.47-3.216,5.35-10.892-6.53v6.98h-6.25v-6.98l-10.892,6.53-3.216-5.35,14.108-8.47v-4.48h-18.75a6.251,6.251,0,0,1-6.25-6.25v-31.25H378V1111h62.5v6.25Z" transform="translate(210.61 370.2)" fill="#fff" fillRule="evenodd"/>
                      <path d="M0,0H45V33H0Z" transform="translate(597.61 1486.2)" fill="#3a3a3a"/>
                      <path d="M403,1142.25h-6.25v-12.5H403Z" transform="translate(210.61 370.2)" fill="#ff4500" fillRule="evenodd"/>
                      <path d="M412.375,1142.25h-6.25V1123.5h6.25Z" transform="translate(210.61 370.2)" fill="#ff4500" fillRule="evenodd"/>
                      <path d="M421.75,1142.25H415.5v-9.37h6.25Z" transform="translate(210.61 370.2)" fill="#ff4500" fillRule="evenodd"/>
                    </g>
                  </svg>
                  </div>
                  <div className="p_main-page__why-investors-choose__tile__description">{item6}</div>
                </div>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

const SectionFive = withLang({
  en: {
    title1: `Why `,
    title2: `investors`,
    title3: `choose`,
    item1: `Transparent trading history`,
    item2: `Automated and accurate statistics about traders`,
    item3: `No trading skills needed to copy trade`,
    item4: `Free access to all features`,
    item5: `Effective risk management`,
    item6: `No fee before you get profit`,
  },
  ru: {
    title1: `Почему`,
    title2: `инвесторы`,
    title3: `выбирают`,
    item1: `Прозрачная история торговли`,
    item2: `Автоматическая и точная статистика трейдеров`,
    item3: `Не нужно быть специалистом чтобы копировать сделки`,
    item4: `Бесплатный доступ ко всем функциям платформы`,
    item5: `Эффективный риск менеджмент`,
    item6: `Никаких комиссий до получения прибыли`,
  }
})(SectionFiveNoLang);

export default SectionFive;
